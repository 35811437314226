import { createAsyncThunk, AnyAction, createSlice } from "@reduxjs/toolkit";
import { SnackbarOrigin } from "@mui/material";

interface toastNotificationDTO extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  //   snackSeverity?: "error" | "info" | "success" | "warning";
  snackSeverity?: string;
  leadUpdateErrorData: any;
}

export const initialState: toastNotificationDTO = {
  snackOpen: false,
  vertical: "top",
  horizontal: "right",
  message: "",
  snackSeverity: "info",
  leadUpdateErrorData: null,
};

// export const showNotification = createAsyncThunk(
//   "toast",
//   async (data: toastNotificationDTO) => {
//     return SaveImportedFile(data)
//       .then((response: any) => {
//         return response?.data?.data;
//       })
//       .catch(function (e: any) {
//         throw e.response.data;
//       });
//   }
// );

export const toastNotificationSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    reset: () => initialState,
    setToastNotification: (state, action) => {
      const data = action.payload;
      state.snackOpen = data?.snackOpen;
      state.message = data?.message;
      state.vertical = "top";
      state.horizontal = "right";
      state.snackSeverity = data.type;
    },
    setLeadExistData: (state, action) => {
      const data = action.payload;
      state.leadUpdateErrorData = data;
    },
  },
  //   extraReducers: (builder) => {
  //     builder.addCase(saveImportedFile.pending, (state) => {
  //       state.saveImportedDataLoading = true;
  //       state.saveImportedDataSuccess = false;
  //       state.saveImportedDataError = false;
  //     });
  //     builder.addCase(saveImportedFile.fulfilled, (state, action: AnyAction) => {
  //       state.saveImportedDataLoading = false;
  //       state.saveImportedDataSuccess = true;
  //       state.saveImportedDataError = false;
  //     });
  //     builder.addCase(saveImportedFile.rejected, (state, action: AnyAction) => {
  //       state.saveImportedDataLoading = false;
  //       state.saveImportedDataSuccess = false;
  //       state.saveImportedDataError =
  //         action?.error?.message || "Something went wrong";
  //     });
  //   },
});

export default toastNotificationSlice.reducer;
export const { reset, setToastNotification, setLeadExistData } =
  toastNotificationSlice.actions;
