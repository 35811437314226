import {
  axios_institute_private,
  axios_signup,
} from "../../../../../api/setup.intersepter";
import { createStaffDTO } from "./types";

export const CreateStaff = (body: createStaffDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/team`,
    data: body,
  });
};

export const GetListStaff = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/team?instituteId=${id}&status=ALL`,
  });
};

export const GetStaffById = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/team?teamMemberId=${id}`,
  });
};

export const UpdateStaff = (body: createStaffDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/team`,
    data: body,
  });
};

export const GetStaffListWithCount = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/team?instituteId=${id}&status=ALL&loadTasksAndEnquiriesCount=true`,
  });
};
