import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SetActiveInstitute } from "../../../../service/activeInstituteSlice";

import { LogoutRequest, RevokeSession, SignInRequest } from "./signin.request";
import {
  LoginIntialStateDTO,
  LoginResponseData,
  LoginDTO,
  UserDetailsDTO,
  LogoutDTO,
  RevokeSessionDTO,
} from "./types";
import { initSocket } from "../../../../api/socket";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";
import {
  getUserById,
  getUserRoleById,
} from "../../../app/Manage/UserDetails/service/userDetailsSlice";
export const initialState: LoginIntialStateDTO = {
  isLogin: false,
  error: false,
  loading: false,
  userDetails: null,
  isLogout: false,
};

const storeDataInLocalStorage = async (data: LoginResponseData) => {
  try {
    localStorage.setItem(
      "ACTIVE_INSTITUTE",
      JSON.stringify(data.institute[0].instituteDetails)
    );
  } catch (e) {}
};

export const login = createAsyncThunk(
  "user/login",
  async (data: LoginDTO, { dispatch }) => {
    try {
      const response = await SignInRequest(data);
      if (response.status === 200) {
        await storeDataInLocalStorage(response?.data?.data);
        if (response?.data?.data?.userId) {
          // initSocket(
          //   response?.data?.data?.institute?.[0]?.id,
          //   response?.data?.data?.institute?.[0]?.instituteId
          // );
        }
        // dispatch(
        //   SetActiveInstitute(response?.data?.data?.institute[0].instituteId)
        // );
        // dispatch(getUserRoleById(response?.data?.data?.institute?.[0]?.id));
      }
      return response?.data?.data;
    } catch (e: any) {
      const errorResponse = e?.response?.data || "Something went wrong";
      dispatch(
        setToastNotification({
          message: e?.response?.data?.message,
          type: "error",
          snackOpen: true,
        })
      );
      throw errorResponse;
    }
  }
);

export const revokeSession = createAsyncThunk(
  "user/revokesession",
  async (data: RevokeSessionDTO, { dispatch }) => {
    try {
      const response = await RevokeSession(data);
      if (response.status === 200) {
        console.log("RevokeSession", response.data);
      }
      return response;
    } catch (e: any) {
      const errorResponse = e?.response?.data || "Something went wrong";
      throw errorResponse;
    }
  }
);
export const logoutRequest = createAsyncThunk(
  "user/logout",
  async (data: LogoutDTO, thunkAPI: any) => {
    return LogoutRequest(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    reset: () => initialState,
    setUserDetails: (state, action: PayloadAction<UserDetailsDTO>) => {
      return { ...state, userDetails: action.payload };
    },
    resetSignInSliceErrors: (state) => {
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.isLogin = false;
      state.error = false;
    });
    builder.addCase(
      login.fulfilled,
      (state, action: PayloadAction<UserDetailsDTO>) => {
        state.loading = false;
        state.isLogin = true;
        state.error = false;
        state.userDetails = action?.payload;
      }
    );
    builder.addCase(login.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.isLogin = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(logoutRequest.pending, (state) => {
      state.loading = true;
      state.isLogout = false;
      state.error = false;
    });
    builder.addCase(
      logoutRequest.fulfilled,
      (state, action: PayloadAction<LogoutDTO>) => {
        state.loading = false;
        state.isLogout = true;
        state.error = false;
      }
    );
    builder.addCase(logoutRequest.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.isLogout = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(revokeSession.pending, (state) => {
      state.loading = true;
      state.isLogout = false;
      state.error = false;
    });
    builder.addCase(
      revokeSession.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.isLogout = true;
        state.error = false;
      }
    );
    builder.addCase(revokeSession.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.isLogout = false;
      state.error = action?.error?.message || "Something went wrong";
    });
  },
});

export default loginSlice.reducer;
export const { reset, setUserDetails, resetSignInSliceErrors } =
  loginSlice.actions;
