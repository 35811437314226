import { store } from "../redux/store";
import { BrowserRouter } from "react-router-dom";
import Router from "../router/Router";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material";
import colorJSON from "../assets/colors/colorset-alpha.json";
import { AuthProvider } from "react-auth-kit";
import "./Root.css";
import AutoLogin from "../pages/auth/AutoLogin";

const theme = createTheme({
  palette: {
    primary: {
      light: colorJSON["primary-light"],
      main: colorJSON.primary,
      dark: colorJSON["primary-dark"],
      contrastText: colorJSON.white,
    },
    secondary: {
      light: colorJSON["secondary-light"],
      main: colorJSON.secondary,
      dark: colorJSON["secondary-dark"],
      contrastText: colorJSON.white,
    },
    error: {
      light: colorJSON["error-light"],
      main: colorJSON.error,
      dark: colorJSON["error-dark"],
      contrastText: colorJSON.black,
    },
    warning: {
      light: colorJSON["warning-light"],
      main: colorJSON.warning,
      dark: colorJSON["warning-dark"],
      contrastText: colorJSON.black,
    },
  },
  typography: {
    fontFamily: ["poppins"].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none", // Remove the box shadow on hover
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#11C89B",
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 9999999,
        },
      },
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <AuthProvider
        authType={"localstorage"}
        authName={"_auth"}
        cookieDomain={window.location.hostname}
        cookieSecure={false}
      >
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <AutoLogin />
            <Router />
          </ThemeProvider>
        </BrowserRouter>
      </AuthProvider>
    </Provider>
  );
}

export default App;
