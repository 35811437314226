import {
  createAsyncThunk,
  AnyAction,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import {
  CreateStaff,
  GetListStaff,
  GetStaffById,
  GetStaffListWithCount,
  UpdateStaff,
} from "./Staff.request";
import { createStaffDTO, staffInitialStateDTO } from "./types";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: staffInitialStateDTO = {
  staffList: null,
  staffById: null,
  staffListWithCount: null,
  success: false,
  error: false,
  loading: false,
  staffListLoading: false,
  createStaffLoading: false,
  updateStaffLoading: false,
  staffByIdLoading: false,
};

export const createStaff = createAsyncThunk(
  "user/staff",
  async (data: createStaffDTO, thunkAPI: any) => {
    return CreateStaff(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getStaffList = createAsyncThunk(
  "user/staffList",
  async (id: string, thunkAPI: any) => {
    return GetListStaff(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getStaffById = createAsyncThunk(
  "user/staffById",
  async (id: string, thunkAPI: any) => {
    return GetStaffById(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const updateStaff = createAsyncThunk(
  "user/updateStaff",
  async (data: createStaffDTO, thunkAPI: any) => {
    return UpdateStaff(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getStaffListWithCount = createAsyncThunk(
  "user/staffListWithCount",
  async (id: string, thunkAPI: any) => {
    return GetStaffListWithCount(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const staffSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(createStaff.pending, (state) => {
      state.createStaffLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(createStaff.fulfilled, (state, action: AnyAction) => {
      state.createStaffLoading = false;
      state.success = action?.payload?.message || "Team Member Created";
      state.error = false;
    });
    builder.addCase(createStaff.rejected, (state, action: AnyAction) => {
      state.createStaffLoading = false;
      state.success = false;
      state.error = action?.payload?.message || "Something went wrong";
    });
    builder.addCase(getStaffList.pending, (state) => {
      state.staffListLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getStaffList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.staffListLoading = false;
        state.success = true;
        state.error = false;
        state.staffList = action?.payload;
      }
    );
    builder.addCase(getStaffList.rejected, (state, action: AnyAction) => {
      state.staffListLoading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getStaffById.pending, (state) => {
      state.staffByIdLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getStaffById.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.staffByIdLoading = false;
        state.success = true;
        state.error = false;
        state.staffById = action?.payload;
      }
    );
    builder.addCase(getStaffById.rejected, (state, action: AnyAction) => {
      state.staffByIdLoading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(updateStaff.pending, (state) => {
      state.updateStaffLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(updateStaff.fulfilled, (state, action: AnyAction) => {
      state.updateStaffLoading = false;
      state.success = action?.payload?.message || "Team Member Updated";
      state.error = false;
    });
    builder.addCase(updateStaff.rejected, (state, action: AnyAction) => {
      state.updateStaffLoading = false;
      state.success = false;
      state.error = action?.payload?.message || "Something went wrong";
    });
    builder.addCase(getStaffListWithCount.pending, (state) => {
      state.staffListLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getStaffListWithCount.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.staffListLoading = false;
        state.success = true;
        state.error = false;
        state.staffListWithCount = action?.payload;
      }
    );
    builder.addCase(
      getStaffListWithCount.rejected,
      (state, action: AnyAction) => {
        state.staffListLoading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default staffSlice.reducer;
export const { reset } = staffSlice.actions;
