import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { GetInstituteByIdRequest } from "../pages/app/Manage/InstituteProfile/service/institute.request";
import { ActiveInstituteIntialStateDTO, instituteDetailsDTO } from "./types";
import { updateInstituteDetails } from "../pages/app/Manage/InstituteProfile/service/instituteSlice";

export const initialState: ActiveInstituteIntialStateDTO = {
  error: false,
  loading: false,
  success: false,
  data: null,
};

const storeDataInLocalStorage = async (data: any) => {
  try {
    localStorage.setItem("ACTIVE_INSTITUTE", JSON.stringify(data));
  } catch (e) {}
};

export const SetActiveInstitute = createAsyncThunk(
  "user/activeInstitute",
  (data: string, { dispatch }) => {
    return GetInstituteByIdRequest(data)
      .then((response: any) => {
        if (response.status === 200) {
          storeDataInLocalStorage(response?.data?.data);
          dispatch(updateInstituteDetails(response?.data?.data));
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const activeInstituteSlice = createSlice({
  name: "activeInstitute",
  initialState,
  reducers: {
    reset: () => initialState,
    setActiveInsitute: (state, action: PayloadAction<instituteDetailsDTO>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(SetActiveInstitute.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    });
    builder.addCase(
      SetActiveInstitute.fulfilled,
      (state, action: PayloadAction<instituteDetailsDTO>) => {
        state.loading = false;
        state.error = false;
        state.success = true;
        state.data = action?.payload;
      }
    );
    builder.addCase(SetActiveInstitute.rejected, (state, action: AnyAction) => {
      state.success = false;
      state.loading = false;
      state.error = action?.error?.message || "Something went wrong";
    });
  },
});

export default activeInstituteSlice.reducer;
export const { reset, setActiveInsitute } = activeInstituteSlice.actions;
