import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  TasksInitialStateDTO,
  UpdateTaskAssigneeDTO,
  UpdateTaskPriorityDTO,
  UpdateTaskStatusDTO,
} from "./types";
import {
  GetAllTaskByAssignee,
  GetAllTaskByDateForAssignee,
  GetAllTaskType,
  GetTaskBoard,
  GetTaskById,
  UpdateTaskAssignee,
  UpdateTaskPriority,
  UpdateTaskStatus,
} from "./tasks.request";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: TasksInitialStateDTO = {
  success: false,
  error: false,
  loading: false,
  tasksByAssignee: null,
  tasksTypes: null,
  tasksboard: null,
  taskById: null,
  tasksByDateForAssignee: null,
  taskStatusUpdateSuccess: false,
  taskStatusUpdateError: false,
  taskStatusUpdateLoading: false,
  taskPriorityUpdateSuccess: false,
  taskPriorityUpdateError: false,
  taskPriorityUpdateLoading: false,
  taskAssigneeUpdateSuccess: false,
  taskAssigneeUpdateError: false,
  taskAssigneeUpdateLoading: false,
  taskByIdLoading: false,
  taskBoardloading: false,
};

export const gettasksByAssignee = createAsyncThunk(
  "tasks/gettasksByAssignee",
  async (id: string, thunkAPI: any) => {
    return GetAllTaskByAssignee(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getAllTasksType = createAsyncThunk(
  "tasks/getAllTasksType",
  async (id: string, thunkAPI: any) => {
    return GetAllTaskType(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const updateTaskStatus = createAsyncThunk(
  "tasks/updateTaskStatus",
  async (data: UpdateTaskStatusDTO, thunkAPI: any) => {
    return UpdateTaskStatus(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

// export const gettasksBoard = createAsyncThunk(
//   "tasks/gettasksBoard",
//   async (data: any, thunkAPI: any) => {
//     return GetTaskBoard(
//       data.id,
//       data.startDate,
//       data.endDate,
//       data.taskType,
//       data.priority
//     )
//       .then((response: any) => {
//         return response?.data?.data;
//       })
//       .catch(function (e: any) {
//         throw e.response.data;
//       });
//   }
// );
export const gettasksBoard = createAsyncThunk(
  "tasks/gettasksBoard",
  async (data: any, thunkAPI: any) => {
    return GetTaskBoard(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const gettasksByDateForAssignee = createAsyncThunk(
  "tasks/gettasksByDateForAssignee",
  async (data: any, thunkAPI: any) => {
    return GetAllTaskByDateForAssignee(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getTaskById = createAsyncThunk(
  "tasks/getTaskById",
  async (id: string, thunkAPI: any) => {
    return GetTaskById(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const updateTaskPriority = createAsyncThunk(
  "tasks/updateTaskPriority",
  async (data: UpdateTaskPriorityDTO, thunkAPI: any) => {
    return UpdateTaskPriority(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const updateTaskAssignee = createAsyncThunk(
  "tasks/updateTaskAssignee",
  async (data: UpdateTaskAssigneeDTO, thunkAPI: any) => {
    return UpdateTaskAssignee(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    reset: () => initialState,
    resetTaskById: (state) => {
      state.taskById = null;
    },
    resetTaskUpdateSuccess: (state) => {
      state.taskStatusUpdateSuccess = false;
    },
    resetTaskPriorityUpdateSuccess: (state) => {
      state.taskPriorityUpdateSuccess = false;
    },
    resetTaskAssigneeUpdateSuccess: (state) => {
      state.taskAssigneeUpdateSuccess = false;
    },
    resetTaskErrors: (state) => {
      state.taskStatusUpdateError = false;
      state.taskAssigneeUpdateError = false;
      state.taskPriorityUpdateError = false;
    },
    resetTaskBoard: (state) => {
      state.tasksboard = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(gettasksByAssignee.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      gettasksByAssignee.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.tasksByAssignee = action?.payload;
      }
    );
    builder.addCase(gettasksByAssignee.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAllTasksType.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getAllTasksType.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.tasksTypes = action?.payload;
      }
    );
    builder.addCase(getAllTasksType.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(updateTaskStatus.pending, (state) => {
      state.taskStatusUpdateLoading = true;
      state.taskStatusUpdateSuccess = false;
      state.taskStatusUpdateError = false;
    });
    builder.addCase(updateTaskStatus.fulfilled, (state, action: AnyAction) => {
      state.taskStatusUpdateLoading = false;
      state.taskStatusUpdateSuccess =
        action?.payload?.message || "Task Status Updated";
      state.taskStatusUpdateError = false;
    });
    builder.addCase(updateTaskStatus.rejected, (state, action: AnyAction) => {
      state.taskStatusUpdateLoading = false;
      state.taskStatusUpdateSuccess = false;
      state.taskStatusUpdateError =
        action?.payload?.message || "Something went wrong";
    });
    builder.addCase(
      gettasksBoard.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.taskBoardloading = false;
        state.success = true;
        state.error = false;
        state.tasksboard = action?.payload;
      }
    );
    builder.addCase(gettasksBoard.rejected, (state, action: AnyAction) => {
      state.taskBoardloading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(gettasksBoard.pending, (state) => {
      state.taskBoardloading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(getTaskById.pending, (state) => {
      state.taskByIdLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getTaskById.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.taskByIdLoading = false;
        state.success = true;
        state.error = false;
        state.taskById = action?.payload;
      }
    );
    builder.addCase(getTaskById.rejected, (state, action: AnyAction) => {
      state.taskByIdLoading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(updateTaskPriority.pending, (state) => {
      state.taskPriorityUpdateLoading = true;
      state.taskPriorityUpdateSuccess = false;
      state.taskPriorityUpdateError = false;
    });
    builder.addCase(
      updateTaskPriority.fulfilled,
      (state, action: AnyAction) => {
        state.taskPriorityUpdateLoading = false;
        state.taskPriorityUpdateSuccess =
          action?.payload?.message || "Task Priority Updated";
        state.taskPriorityUpdateError = false;
      }
    );
    builder.addCase(updateTaskPriority.rejected, (state, action: AnyAction) => {
      state.taskPriorityUpdateLoading = false;
      state.taskPriorityUpdateSuccess = false;
      state.taskPriorityUpdateError =
        action?.payload?.message || "Something went wrong";
    });
    builder.addCase(updateTaskAssignee.pending, (state) => {
      state.taskAssigneeUpdateLoading = true;
      state.taskAssigneeUpdateSuccess = false;
      state.taskAssigneeUpdateError = false;
    });
    builder.addCase(
      updateTaskAssignee.fulfilled,
      (state, action: AnyAction) => {
        state.taskAssigneeUpdateLoading = false;
        state.taskAssigneeUpdateSuccess =
          action?.payload?.message || "Task Assignee Updated";
        state.taskAssigneeUpdateError = false;
      }
    );
    builder.addCase(updateTaskAssignee.rejected, (state, action: AnyAction) => {
      state.taskAssigneeUpdateLoading = false;
      state.taskAssigneeUpdateSuccess = false;
      state.taskAssigneeUpdateError =
        action?.payload?.message || "Something went wrong";
    });
    builder.addCase(gettasksByDateForAssignee.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      gettasksByDateForAssignee.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.tasksByDateForAssignee = action?.payload;
      }
    );
    builder.addCase(
      gettasksByDateForAssignee.rejected,
      (state, action: AnyAction) => {
        state.loading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default tasksSlice.reducer;
export const {
  reset,
  resetTaskById,
  resetTaskPriorityUpdateSuccess,
  resetTaskAssigneeUpdateSuccess,
  resetTaskUpdateSuccess,
  resetTaskErrors,
  resetTaskBoard,
} = tasksSlice.actions;
