import { axios_institute_private } from "../../../../../api/setup.intersepter";

import { CreateTagDTO } from "./types";

export const CreateTagRequest = (body: CreateTagDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/courseTag`,
    data: body,
  });
};
