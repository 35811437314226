import {
  axios_institute_private,
  axios_ozonetel,
} from "../../../../api/setup.intersepter";
import {
  AddAgentsDTO,
  CallDispositionDTO,
  ConnectOzonetelDTO,
  CreateAppTokenDTO,
  NurturePayDTO,
} from "./types";

export const ConnectOzonetel = (body: ConnectOzonetelDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/institution/apps`,
    data: body,
  });
};

export const DeactivateOzonetel = (id: string) => {
  return axios_institute_private({
    method: "DELETE",
    url: `/institution/apps?instituteId=${id}&appCode=OZONETEL`,
    data: id,
  });
};

export const AddOzonetelAgents = (body: AddAgentsDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/institution/ozonetel/agent`,
    data: body,
  });
};

export const GetAppsByInstitute = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/institution/apps?instituteId=${id}`,
  });
};

export const ValidateAgentId = (
  agentId: string,
  instituteId: string,
  apiKey: string,
  userName: string
) => {
  return axios_institute_private({
    method: "GET",
    url: `institution/ozonetel/agent?checkAgentStatus=true&agentId=${agentId}&instituteId=${instituteId}&apiKey=${apiKey}&userName=${userName}`,
  });
};

export const UpdateOzonetelAgents = (body: AddAgentsDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/institution/ozonetel/agent`,
    data: body,
  });
};

export const CallLead = (
  apiKey: string,
  userName: string,
  agentId: string,
  campaignName: string,
  leadNumber: string,
  instituteId: string,
  leadId: string,
  appEnv: string
) => {
  return axios_ozonetel({
    method: "GET",
    url: `https://in1-ccaas-api.ozonetel.com/CAServices/AgentManualDial.php?api_key=${apiKey}&username=${userName}&agentID=${agentId}&campaignName=${campaignName}&customerNumber=${leadNumber}&UCID=true&format=json&uui=INSTID%23${instituteId}%23ENQID%23${leadId}%23ENV%23${appEnv}`,
  });
};

export const InstallNurturePay = (body: NurturePayDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/institution/apps`,
    data: body,
  });
};

export const CallLeadStatus = (apiKey: string, userName: string) => {
  return axios_ozonetel({
    method: "GET",
    url: `https://in1-ccaas-api.ozonetel.com/cloudAgentRestAPI/index.php/CloudAgent/CloudAgentAPI/getAgentLogins?api_key=${apiKey}&username=${userName}&format=json`,
  });
};

export const LeadCallDispositionForm = (body: CallDispositionDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/nurture/lead/addDispostion`,
    data: body,
  });
};

export const SetOzonetelDisposition = (
  apiKey: string,
  userName: string,
  agentId: string,
  did: string,
  ucid: string,
  reasons: string
) => {
  const encodedReasons = encodeURIComponent(reasons);
  return axios_ozonetel({
    method: "POST",
    url: `https://in1-ccaas-api.ozonetel.com/CAServices/Disposition/DispositionAPIV2.php?customer=${userName}&agentID=${agentId}&apiKey=${apiKey}&did=${did}&ucid=${ucid}&action=set&disposition=${encodedReasons}`,
  });
};

export const CreateAppToken = (body: CreateAppTokenDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/institute/token`,
    data: body,
  });
};

export const GetAppToken = (id: string, appName: string) => {
  return axios_institute_private({
    method: "GET",
    url: `institute/token?instituteId=${id}&appName=${appName}`,
  });
};

export const AppIntegrateToNurture = (body: ConnectOzonetelDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/institution/apps`,
    data: body,
  });
};

export const UninstallTokenGeneratedApp = (id: string, appName: string) => {
  return axios_institute_private({
    method: "DELETE",
    url: `institute/token?instituteId=${id}&appName=${appName}`,
    data: id,
  });
};
