import {
  axios,
  axios_institute_public,
} from "../../../../api/setup.intersepter";

import {
  createInstituteDTO,
  createInstituteVerifyOTPDTO,
  setPasswordDTO,
} from "./types";

export const CreateInstitute = (body: createInstituteDTO) => {
  return axios_institute_public({
    method: "POST",
    url: `/institutions/createInstituteProfile`,
    data: body,
  });
};

export const CreateInstituteVerifyOTPRequest = (
  body: createInstituteVerifyOTPDTO
) => {
  return axios({
    method: "POST",
    url: `/verifyOtp`,
    data: body,
  });
};

export const CreateInstitutesetPasswordRequest = (body: setPasswordDTO) => {
  return axios({
    method: "POST",
    url: `/setPassword`,
    data: body,
  });
};

export const CheckSubDomainRequest = (body: string) => {
  return axios_institute_public({
    method: "POST",
    url: `/institutions/updateSubdomain`,
    data: body,
  });
};
