// src/store/compressSlice.js
import { createSlice } from "@reduxjs/toolkit";

const compressSlice = createSlice({
  name: "compress",
  initialState: {
    isCompress: false,
  },
  reducers: {
    toggleCompresser: (state) => {
      state.isCompress = !state.isCompress;
    },
    setCompress: (state, action) => {
      state.isCompress = action.payload;
    },
  },
});

export const { toggleCompresser, setCompress } = compressSlice.actions;

export default compressSlice.reducer;
