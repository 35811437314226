import { axios_institute_private } from "../../../../../api/setup.intersepter";

export const GetNotificationsByPagination = (
  userId: string,
  lastEvaluatedKey: string,
  limit: number,
  lastEvaluatedSortKey: string
) => {
  let url = `/notification?limit=${limit}&userId=${userId}`;

  if (lastEvaluatedKey) {
    url += `&lastEvaluatedKey=${lastEvaluatedKey}`;
  }

  if (lastEvaluatedSortKey) {
    url += `&lastEvaluatedSortKey=${lastEvaluatedSortKey}`;
  }

  return axios_institute_private({
    method: "GET",
    url: url,
  });
};

export const GetNotificationsByPaginationPopover = (
  userId: string,
  lastEvaluatedKey: string,
  limit: number,
  lastEvaluatedSortKey: string
) => {
  let url = `/notification?limit=${limit}&userId=${userId}`;

  if (lastEvaluatedKey) {
    url += `&lastEvaluatedKey=${lastEvaluatedKey}`;
  }

  if (lastEvaluatedSortKey) {
    url += `&lastEvaluatedSortKey=${lastEvaluatedSortKey}`;
  }

  return axios_institute_private({
    method: "GET",
    url: url,
  });
};

export const MarkNotificationAsRead = (id: string) => {
  return axios_institute_private({
    method: "PUT",
    url: `/notification?notificationId=${id}&readStatus=READ`,
  });
};

export const MarkAllAsRead = (id: string) => {
  return axios_institute_private({
    method: "PUT",
    url: `/notification?readStatus=READ&userId=${id}`,
  });
};
