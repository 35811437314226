import {
  createAsyncThunk,
  AnyAction,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import {
  AddAgentsDTO,
  CallDispositionDTO,
  ConnectOzonetelDTO,
  CreateAppTokenDTO,
  IntegrationsInitialStateDTO,
  NurturePayDTO,
} from "./types";
import {
  AddOzonetelAgents,
  AppIntegrateToNurture,
  CallLead,
  ConnectOzonetel,
  CreateAppToken,
  DeactivateOzonetel,
  GetAppToken,
  GetAppsByInstitute,
  InstallNurturePay,
  LeadCallDispositionForm,
  UninstallTokenGeneratedApp,
  UpdateOzonetelAgents,
  ValidateAgentId,
} from "./integrations.request";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: IntegrationsInitialStateDTO = {
  appsList: null,
  validatedAgent: null,
  appTokenData: null,
  success: false,
  error: false,
  loading: false,
  ozonetelConnectError: false,
  ozonetelConnectLoading: false,
  ozonetelConnectSuccess: false,
  deactivateOzonetelLoading: false,
  deactivateOzonetelSuccess: false,
  deactivateOzonetelError: false,
  agentAddedError: false,
  agentAddedLoading: false,
  agentAddedSuccess: false,
  validateAgentError: false,
  validateAgentLoading: false,
  validateAgentSuccess: false,
  agentUpdatedError: false,
  agentUpdatedLoading: false,
  agentUpdatedSuccess: false,
  callLeadError: false,
  callLeadLoading: false,
  callLeadSuccess: false,
  nurturePayInstalledLoading: false,
  nurturePayInstalledSuccess: false,
  nurturePayInstalledError: false,

  ozonetelWidgetShow: false,
  callDispositionLoading: false,
  callDispositionSuccess: false,
  callDispositionError: false,
  appTokenLoading: false,
  appTokenSuccess: false,
  appTokenError: false,
  appTokenGetLoading: false,
  appIntegrationloading: false,
  appIntegrationError: false,
  appIntegrationSuccess: false,
  uninstallLoading: false,
  uninstallSuccess: false,
  uninstallError: false,
};

export const connectOzonetel = createAsyncThunk(
  "appIntegrations/connectOzonetel",
  async (data: ConnectOzonetelDTO, thunkAPI: any) => {
    return ConnectOzonetel(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const deactivateOzonetel = createAsyncThunk(
  "appIntegrations/deactivateOzonetel",
  async (data: any, thunkAPI: any) => {
    return DeactivateOzonetel(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const addOzonetelAgents = createAsyncThunk(
  "appIntegrations/addOzonetelAgents",
  async (data: AddAgentsDTO, thunkAPI: any) => {
    return AddOzonetelAgents(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getAppsByInstitute = createAsyncThunk(
  "appIntegrations/getAppsByInstitute",
  async (id: string) => {
    return GetAppsByInstitute(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const validateAgentId = createAsyncThunk(
  "appIntegrations/validateAgentId",
  async (data: any, thunkAPI: any) => {
    return ValidateAgentId(
      data.agentId,
      data.instituteId,
      data.apiKey,
      data.userName
    )
      .then((response: any) => {
        // if (response) {
        //   thunkAPI.dispatch(
        //     setToastNotification({
        //       message: response?.data?.message,
        //       type: "success",
        //       snackOpen: true,
        //     })
        //   );
        // }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        //  thunkAPI.dispatch(
        //    setToastNotification({
        //      message: e?.response?.data?.message,
        //      type: "error",
        //      snackOpen: true,
        //    })
        //  );
        throw e.response.data;
      });
  }
);

export const updateOzonetelAgents = createAsyncThunk(
  "appIntegrations/updateOzonetelAgents",
  async (data: AddAgentsDTO, thunkAPI: any) => {
    return UpdateOzonetelAgents(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const callLead = createAsyncThunk(
  "appIntegrations/callLead",
  async (data: any, thunkAPI: any) => {
    return CallLead(
      data.apiKey,
      data.userName,
      data.agentId,
      data.campaignName,
      data.leadNumber,
      data.instituteId,
      data.leadId,
      data.appEnv
    )
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const installNurturePay = createAsyncThunk(
  "appIntegrations/installNurturePay",
  async (data: NurturePayDTO, thunkAPI: any) => {
    return InstallNurturePay(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const leadCallDispositionForm = createAsyncThunk(
  "appIntegrations/leadCallDispositionForm",
  async (data: CallDispositionDTO, thunkAPI: any) => {
    return LeadCallDispositionForm(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const createAppToken = createAsyncThunk(
  "appIntegrations/createAppToken",
  async (data: CreateAppTokenDTO, thunkAPI: any) => {
    return CreateAppToken(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getAppToken = createAsyncThunk(
  "appIntegrations/getAppToken",
  async (data: any, thunkAPI: any) => {
    return GetAppToken(data.id, data.appName)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const appIntegrateToNurture = createAsyncThunk(
  "appIntegrations/integration",
  async (data: ConnectOzonetelDTO, thunkAPI: any) => {
    return AppIntegrateToNurture(data)
      .then((response: any) => {
        return response?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const uninstallTokenGeneratedApp = createAsyncThunk(
  "appIntegrations/uninstallTokenGeneratedApp",
  async (data: any, thunkAPI: any) => {
    return UninstallTokenGeneratedApp(data.id, data.appName)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const integrationsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    reset: () => initialState,
    resetOzonetelConnectStatuses: (state) => {
      state.ozonetelConnectSuccess = false;
      state.ozonetelConnectError = false;
    },
    resetDeactivateConnectStatuses: (state) => {
      state.deactivateOzonetelSuccess = false;
      state.deactivateOzonetelError = false;
    },
    resetAgentStatuses: (state) => {
      state.agentAddedSuccess = false;
      state.agentAddedError = false;
      state.agentUpdatedSuccess = false;
      state.agentUpdatedError = false;
    },
    resetNurturePayConnectStatuses: (state) => {
      state.nurturePayInstalledSuccess = false;
      state.nurturePayInstalledError = false;
    },
    setShowOzonetelWidget: (state) => {
      state.ozonetelWidgetShow = true;
    },
    setHideOzonetelWidget: (state) => {
      state.ozonetelWidgetShow = false;
    },
    resetCallDispositionFormStatuses: (state) => {
      state.callDispositionSuccess = false;
      state.callDispositionError = false;
    },
    resetUninstallStatuses: (state) => {
      state.uninstallError = false;
      state.uninstallSuccess = false;
    },
    resetAppTokenStatuses: (state) => {
      state.appTokenSuccess = false;
      state.appTokenError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(connectOzonetel.pending, (state) => {
      state.ozonetelConnectLoading = true;
      state.ozonetelConnectSuccess = false;
      state.ozonetelConnectError = false;
    });
    builder.addCase(connectOzonetel.fulfilled, (state, action: AnyAction) => {
      state.ozonetelConnectLoading = false;
      state.ozonetelConnectSuccess =
        action?.success?.message || "Ozonetel account connected successfully!";
      //   state.ozonetelConnectSuccess = action?.success?.message;
      state.ozonetelConnectError = false;
    });
    builder.addCase(connectOzonetel.rejected, (state, action: AnyAction) => {
      state.ozonetelConnectLoading = false;
      state.ozonetelConnectSuccess = false;
      state.ozonetelConnectError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(deactivateOzonetel.pending, (state) => {
      state.deactivateOzonetelLoading = true;
      state.deactivateOzonetelSuccess = false;
      state.deactivateOzonetelError = false;
    });
    builder.addCase(
      deactivateOzonetel.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.deactivateOzonetelLoading = false;
        state.deactivateOzonetelSuccess = true;
        state.deactivateOzonetelError = false;
      }
    );
    builder.addCase(deactivateOzonetel.rejected, (state, action: AnyAction) => {
      state.deactivateOzonetelLoading = false;
      state.deactivateOzonetelSuccess = false;
      state.deactivateOzonetelError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(addOzonetelAgents.pending, (state) => {
      state.agentAddedLoading = true;
      state.agentAddedSuccess = false;
      state.agentAddedError = false;
    });
    builder.addCase(addOzonetelAgents.fulfilled, (state, action: AnyAction) => {
      state.agentAddedLoading = false;
      state.agentAddedSuccess =
        action?.success?.message || "Ozonetel agents added successfully!";
      state.agentAddedError = false;
    });
    builder.addCase(addOzonetelAgents.rejected, (state, action: AnyAction) => {
      state.agentAddedLoading = false;
      state.agentAddedSuccess = false;
      state.agentAddedError = action?.error?.message || "Something went wrong";
    });

    builder.addCase(
      validateAgentId.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.validateAgentLoading = false;
        state.validateAgentSuccess = true;
        state.validateAgentError = false;
        state.validatedAgent = action?.payload;
      }
    );
    builder.addCase(validateAgentId.rejected, (state, action: AnyAction) => {
      state.validateAgentLoading = false;
      state.validateAgentSuccess = false;
      state.validateAgentError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(validateAgentId.pending, (state) => {
      state.validateAgentLoading = true;
      state.validateAgentSuccess = false;
      state.validateAgentError = false;
    });
    builder.addCase(
      getAppsByInstitute.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.appsList = action?.payload;
      }
    );
    builder.addCase(getAppsByInstitute.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAppsByInstitute.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });

    builder.addCase(updateOzonetelAgents.pending, (state) => {
      state.agentUpdatedLoading = true;
      state.agentUpdatedSuccess = false;
      state.agentUpdatedError = false;
    });
    builder.addCase(
      updateOzonetelAgents.fulfilled,
      (state, action: AnyAction) => {
        state.agentUpdatedLoading = false;
        state.agentUpdatedSuccess =
          action?.success?.message || "Ozonetel agents updated successfully!";
        state.agentUpdatedError = false;
      }
    );
    builder.addCase(
      updateOzonetelAgents.rejected,
      (state, action: AnyAction) => {
        state.agentUpdatedLoading = false;
        state.agentUpdatedSuccess = false;
        state.agentUpdatedError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(callLead.pending, (state) => {
      state.agentAddedLoading = true;
      state.agentAddedSuccess = false;
      state.agentAddedError = false;
    });
    builder.addCase(callLead.fulfilled, (state, action: AnyAction) => {
      state.agentAddedLoading = false;
      state.agentAddedSuccess =
        action?.success?.message || "Ozonetel agents added successfully!";
      state.agentAddedError = false;
    });
    builder.addCase(callLead.rejected, (state, action: AnyAction) => {
      state.agentAddedLoading = false;
      state.agentAddedSuccess = false;
      state.agentAddedError = action?.error?.message || "Something went wrong";
    });

    builder.addCase(installNurturePay.pending, (state) => {
      state.nurturePayInstalledLoading = true;
      state.nurturePayInstalledSuccess = false;
      state.nurturePayInstalledError = false;
    });
    builder.addCase(installNurturePay.fulfilled, (state, action: AnyAction) => {
      state.nurturePayInstalledLoading = false;
      state.nurturePayInstalledSuccess =
        action?.success?.message || "Request Successfully Registered!";
      state.nurturePayInstalledError = false;
    });
    builder.addCase(installNurturePay.rejected, (state, action: AnyAction) => {
      state.callDispositionLoading = false;
      state.callDispositionSuccess = false;
      state.callDispositionError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(leadCallDispositionForm.pending, (state) => {
      state.callDispositionLoading = true;
      state.callDispositionSuccess = false;
      state.callDispositionError = false;
    });
    builder.addCase(
      leadCallDispositionForm.fulfilled,
      (state, action: AnyAction) => {
        state.callDispositionLoading = false;
        state.callDispositionSuccess =
          action?.success?.message || "Disposition added";
        state.callDispositionError = false;
      }
    );
    builder.addCase(
      leadCallDispositionForm.rejected,
      (state, action: AnyAction) => {
        state.nurturePayInstalledLoading = false;
        state.nurturePayInstalledSuccess = false;
        state.nurturePayInstalledError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(createAppToken.pending, (state) => {
      state.appTokenLoading = true;
      state.appTokenSuccess = false;
      state.appTokenError = false;
    });
    builder.addCase(createAppToken.fulfilled, (state, action: AnyAction) => {
      state.appTokenLoading = false;
      state.appTokenSuccess =
        action?.payload?.message || "App token connected successfully!";
      state.appTokenError = false;
    });
    builder.addCase(createAppToken.rejected, (state, action: AnyAction) => {
      state.appTokenLoading = false;
      state.appTokenSuccess = false;
      state.appTokenError = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAppToken.pending, (state) => {
      state.appTokenGetLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getAppToken.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.appTokenGetLoading = false;
        state.success = true;
        state.error = false;
        state.appTokenData = action?.payload;
      }
    );
    builder.addCase(getAppToken.rejected, (state, action: AnyAction) => {
      state.appTokenGetLoading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(appIntegrateToNurture.pending, (state) => {
      state.appIntegrationloading = true;
      state.appIntegrationError = false;
      state.appIntegrationSuccess = false;
    });
    builder.addCase(
      appIntegrateToNurture.fulfilled,
      (state, action: AnyAction) => {
        state.appIntegrationloading = false;
        state.appIntegrationSuccess =
          action?.success?.message || "App integrated successfully!";
        state.appIntegrationError = false;
      }
    );
    builder.addCase(
      appIntegrateToNurture.rejected,
      (state, action: AnyAction) => {
        state.appIntegrationloading = false;
        state.appIntegrationSuccess = false;
        state.appIntegrationError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(uninstallTokenGeneratedApp.pending, (state) => {
      state.uninstallLoading = true;
      state.uninstallSuccess = false;
      state.uninstallError = false;
    });
    builder.addCase(
      uninstallTokenGeneratedApp.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.uninstallLoading = false;
        state.uninstallSuccess =
          action?.payload?.message || "App uninstalled successfully!";
        state.uninstallError = false;
      }
    );
    builder.addCase(
      uninstallTokenGeneratedApp.rejected,
      (state, action: AnyAction) => {
        state.uninstallLoading = false;
        state.uninstallSuccess = false;
        state.uninstallError = action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default integrationsSlice.reducer;
export const {
  reset,
  resetOzonetelConnectStatuses,
  resetDeactivateConnectStatuses,
  resetAgentStatuses,
  setShowOzonetelWidget,
  setHideOzonetelWidget,
  resetNurturePayConnectStatuses,
  resetCallDispositionFormStatuses,
  resetUninstallStatuses,
  resetAppTokenStatuses,
} = integrationsSlice.actions;
