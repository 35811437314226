import {
  axios_institute_private,
} from "../../../../../api/setup.intersepter";

export const getCourseConfig = () => {
  return axios_institute_private({
    method: "GET",
    url: `/course/getCourseConfig`,
  });
};
