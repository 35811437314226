export const updateProfileImage = (data: {}) => {
  return {
    type: "UPDATE_PROFILE_IMAGE",
    payload: data,
  };
};

// profileReducer.ts
interface ProfileState {
  userDetails: {};
}

const initialState: ProfileState = {
  userDetails: {
    profileImage: "",
    firstName: "",
    lastName: "",
  },
};

const profileReducer = (state = initialState, action: any) => {
 
  switch (action.type) {
    case "UPDATE_PROFILE_IMAGE":
      return {
        ...state,
        userDetails: action.payload,
      };
    default:
      return state;
  }
};

export default profileReducer;
