import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { getCourseConfig } from "./courseConfig.request";
import { CourseConfigDTO, CourseConfigInitialStateDTO } from "./types";

export const initialState: CourseConfigInitialStateDTO = {
  success_CourseConfig: false,
  error_CourseConfig: false,
  loading_CourseConfig: false,
  courseConfigs: "",
};

export const GetCourseConfig = createAsyncThunk("user/courseconfig", () => {
  return getCourseConfig()
    .then((response: any) => {
      return response?.data?.data;
    })
    .catch(function (e: any) {
      throw e.response.data;
    });
});

export const courseConfigSlice = createSlice({
  name: "courseConfig",
  initialState,
  reducers: {
    reset: () => initialState,
    setConfigData: (state, action: PayloadAction<CourseConfigDTO>) => {
      return { ...state, data: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetCourseConfig.pending, (state) => {
      state.loading_CourseConfig = true;
      state.success_CourseConfig = false;
      state.error_CourseConfig = false;
    });
    builder.addCase(
      GetCourseConfig.fulfilled,
      (state, action: PayloadAction<CourseConfigDTO>) => {
        state.loading_CourseConfig = false;
        state.success_CourseConfig = true;
        state.error_CourseConfig = false;
        state.courseConfigs = action?.payload;
      }
    );
    builder.addCase(GetCourseConfig.rejected, (state, action: AnyAction) => {
      state.loading_CourseConfig = false;
      state.success_CourseConfig = false;
      state.error_CourseConfig =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default courseConfigSlice.reducer;
export const { reset, setConfigData } = courseConfigSlice.actions;
