import { axios_institute_private } from "../../../../../api/setup.intersepter";
import { createBranchDTO } from "./types";

export const CreateBranch = (body: createBranchDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/branch`,
    data: body,
  });
};

export const GetListBranch = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/branch/getBranchesByInstituteId/${id}?status=ALL`,
    // url: `/branch/getBranchesByInstituteId/${id}?status=ALL&loadLeadData=false`,
  });
};

export const GetBranchById = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/branch/id/${id}`,
  });
};

export const UpdateBranch = (body: createBranchDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/branch`,
    data: body,
  });
};
