import {
  createAsyncThunk,
  AnyAction,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import {
  BillingAutoDebitDTO,
  BillingInitialStateDTO,
  CreateBillingAddressDTO,
  CreateBillingDTO,
  UpdateBillingAdminsDTO,
  UpdateBranchDTO,
  UpdateSubscriptionStatusDTO,
  UpdateUserDTO,
} from "./types";
import {
  BillingAutoDebit,
  CheckPromocodeByName,
  CreateBilling,
  GetBillingAddress,
  GetBillingAdmins,
  GetBillingByInstituteId,
  GetPaymentHistory,
  GetPaymentHistoryByPagination,
  GetPaymentInvoice,
  GetSubscriptionByInstituteId,
  RemoveBillingAdmin,
  RemoveBranchOrBranchLicense,
  RemoveUserOrUserLicense,
  UpdateBillingAddress,
  UpdateBillingAdmins,
  UpdateSubscriptionStatus,
} from "./Billing.request";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: BillingInitialStateDTO = {
  instituteBillingData: null,
  instituteSubscriptionData: null,
  promocodeByName: null,
  billingAdmins: null,
  billingAddress: null,
  paymentHistory: null,
  paymentInvoice: null,
  billingPaymentLink: null,
  success: false,
  error: false,
  loading: false,
  billingAdminSuccess: false,
  billingAdminError: false,
  billingAdminLoading: false,
  userRemovalError: false,
  userRemovalSuccess: false,
  userRemovalLoading: false,
  branchRemovalError: false,
  branchRemovalSuccess: false,
  branchRemovalLoading: false,
  errorBilling: false,
  successBilling: false,
  loadingBilling: false,
  subscriptionStatusError: false,
  subscriptionStatussuccess: false,
  subscriptionStatusloading: false,
  promocodeError: false,
  promocodesuccess: false,
  promocodeloading: false,
  Invoicesuccess: false,
  billingDataLoading: false,
  autoDebitError: false,
  autoDebitSuccess: false,
  autoDebitLoading: false,
};

export const getBillingByInstituteId = createAsyncThunk(
  "user/getBillingByInstituteId",
  async (data: any, thunkAPI: any) => {
    return GetBillingByInstituteId(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getSubscriptionByInstituteId = createAsyncThunk(
  "user/getSubscriptionByInstituteId",
  async (id: string, thunkAPI: any) => {
    return GetSubscriptionByInstituteId(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const createBilling = createAsyncThunk(
  "user/billing",
  async (data: CreateBillingDTO, thunkAPI: any) => {
    return CreateBilling(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const checkPromocodeByName = createAsyncThunk(
  "user/promocodeByName",
  async (id: string, thunkAPI: any) => {
    return CheckPromocodeByName(id)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const updateInstituteSubscriptionStatus = createAsyncThunk(
  "user/subscriptionStatus",
  async (data: UpdateSubscriptionStatusDTO, thunkAPI: any) => {
    return UpdateSubscriptionStatus(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const updateBillingAdmins = createAsyncThunk(
  "user/updateBillingAdmins",
  async (data: UpdateBillingAdminsDTO, thunkAPI: any) => {
    return UpdateBillingAdmins(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getBillingAdmins = createAsyncThunk(
  "user/getBillingAdmins",
  async (id: string, thunkAPI: any) => {
    return GetBillingAdmins(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const removeBillingAdmin = createAsyncThunk(
  "user/removeBillingAdmin",
  async (data: string, thunkAPI: any) => {
    return RemoveBillingAdmin(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const updateBillingAddress = createAsyncThunk(
  "user/updateBillingAddress",
  async (data: CreateBillingAddressDTO, thunkAPI: any) => {
    return UpdateBillingAddress(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getBillingAddress = createAsyncThunk(
  "user/getBillingAddress",
  async (id: string, thunkAPI: any) => {
    return GetBillingAddress(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const removeUserOrUserLicense = createAsyncThunk(
  "user/removeUserOrUserLicense",
  async (data: UpdateUserDTO, thunkAPI: any) => {
    return RemoveUserOrUserLicense(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const removeBranchOrBranchLicense = createAsyncThunk(
  "user/removeBranchOrBranchLicense",
  async (data: UpdateBranchDTO, thunkAPI: any) => {
    return RemoveBranchOrBranchLicense(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

// export const getPaymentHistory = createAsyncThunk(
//   "user/getPaymentHistory",
//   async (id: string, thunkAPI: any) => {
//     return GetPaymentHistory(id)
//       .then((response: any) => {
//         return response?.data?.data;
//       })
//       .catch(function (e: any) {
//         throw e.response.data;
//       });
//   }
// );

export const getPaymentHistoryByPagination = createAsyncThunk(
  "user/getPaymentHistoryByPagination",
  async (
    data: {
      id: string;
      lastEvaluatedKey: string;
      limit: number;
      lastEvaluatedSortKey: string;
    },
    thunkAPI: any
  ) => {
    const { id, lastEvaluatedKey, limit, lastEvaluatedSortKey } = data;

    return GetPaymentHistoryByPagination(
      id,
      lastEvaluatedKey,
      limit,
      lastEvaluatedSortKey
    )
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getPaymentInvoice = createAsyncThunk(
  "user/getPaymentInvoice",
  async (id: string, thunkAPI: any) => {
    return GetPaymentInvoice(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const billingAutoDebit = createAsyncThunk(
  "user/billingAutoDebit",
  async (
    { data, actionType }: { data: BillingAutoDebitDTO; actionType: string },
    thunkAPI: any
  ) => {
    return BillingAutoDebit(data, actionType)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    reset: () => initialState,
    resetPromoCode: (state) => {
      state.promocodeByName = null;
    },
    resetBillingSuccess: (state) => {
      state.successBilling = false;
      state.billingPaymentLink = null;
    },
    resetUserRemovalSuccess: (state) => {
      state.userRemovalSuccess = false;
    },
    resetBranchRemovalSuccess: (state) => {
      state.branchRemovalSuccess = false;
    },
    resetPaymentError: (state) => {
      state.errorBilling = false;
    },
    resetPaymentInvoice: (state) => {
      state.paymentInvoice = null;
      state.Invoicesuccess = false;
    },
    resetPromocodeSuccess: (state) => {
      state.promocodeByName = null;
      state.promocodesuccess = false;
    },
    resetCancellation: (state) => {
      state.subscriptionStatussuccess = false;
      state.subscriptionStatusError = false;
    },
    resetAutoDebitStatuses: (state) => {
      state.autoDebitSuccess = false;
      state.autoDebitError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBillingByInstituteId.pending, (state) => {
      state.billingDataLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getBillingByInstituteId.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.billingDataLoading = false;
        state.success = true;
        state.error = false;
        state.instituteBillingData = action?.payload;
      }
    );
    builder.addCase(
      getBillingByInstituteId.rejected,
      (state, action: AnyAction) => {
        state.billingDataLoading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getSubscriptionByInstituteId.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getSubscriptionByInstituteId.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.instituteSubscriptionData = action?.payload;
      }
    );
    builder.addCase(
      getSubscriptionByInstituteId.rejected,
      (state, action: AnyAction) => {
        state.loading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(createBilling.pending, (state) => {
      state.loadingBilling = true;
      state.successBilling = false;
      state.errorBilling = false;
    });
    builder.addCase(createBilling.fulfilled, (state, action: AnyAction) => {
      state.loadingBilling = false;
      state.successBilling = action?.payload?.message || "Payment Done";
      state.errorBilling = false;
      state.billingPaymentLink = action?.payload?.data;
    });
    builder.addCase(createBilling.rejected, (state, action: AnyAction) => {
      state.loadingBilling = false;
      state.successBilling = false;
      state.errorBilling = action?.payload?.message || "Something went wrong";
    });

    builder.addCase(checkPromocodeByName.pending, (state) => {
      state.promocodeloading = true;
      state.promocodesuccess = false;
      state.promocodeError = false;
    });
    builder.addCase(
      checkPromocodeByName.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.promocodeloading = false;
        state.promocodesuccess =
          action?.payload?.message || "Promocode applied";
        state.promocodeError = false;
        state.promocodeByName = action?.payload?.data;
      }
    );
    builder.addCase(
      checkPromocodeByName.rejected,
      (state, action: AnyAction) => {
        state.promocodeloading = false;
        state.promocodesuccess = false;
        state.promocodeError =
          action?.payload?.message || "Something went wrong";
      }
    );
    builder.addCase(updateInstituteSubscriptionStatus.pending, (state) => {
      state.subscriptionStatusloading = true;
      state.subscriptionStatussuccess = false;
      state.subscriptionStatusError = false;
    });
    builder.addCase(
      updateInstituteSubscriptionStatus.fulfilled,
      (state, action: AnyAction) => {
        state.subscriptionStatusloading = false;
        state.subscriptionStatussuccess =
          action?.payload?.message || "Subscription Status Updated";
        state.subscriptionStatusError = false;
      }
    );
    builder.addCase(
      updateInstituteSubscriptionStatus.rejected,
      (state, action: AnyAction) => {
        state.subscriptionStatusloading = false;
        state.subscriptionStatussuccess = false;
        state.subscriptionStatusError =
          action?.payload?.message || "Something went wrong";
      }
    );
    builder.addCase(updateBillingAdmins.pending, (state) => {
      state.billingAdminLoading = true;
      state.billingAdminSuccess = false;
      state.billingAdminError = false;
    });
    builder.addCase(
      updateBillingAdmins.fulfilled,
      (state, action: AnyAction) => {
        state.billingAdminLoading = false;
        state.billingAdminSuccess =
          action?.payload?.message || "Billing Admins Updated";
        state.billingAdminError = false;
      }
    );
    builder.addCase(
      updateBillingAdmins.rejected,
      (state, action: AnyAction) => {
        state.billingAdminLoading = false;
        state.billingAdminSuccess = false;
        state.billingAdminError =
          action?.payload?.message || "Something went wrong";
      }
    );
    builder.addCase(getBillingAdmins.pending, (state) => {
      state.billingAdminLoading = true;
      state.billingAdminSuccess = false;
      state.billingAdminError = false;
    });
    builder.addCase(
      getBillingAdmins.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.billingAdminLoading = false;
        state.billingAdminSuccess = true;
        state.billingAdminError = false;
        state.billingAdmins = action?.payload;
      }
    );
    builder.addCase(getBillingAdmins.rejected, (state, action: AnyAction) => {
      state.billingAdminLoading = false;
      state.billingAdminSuccess = false;
      state.billingAdminError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(removeBillingAdmin.pending, (state) => {
      state.billingAdminLoading = true;
      state.billingAdminSuccess = false;
      state.billingAdminError = false;
    });
    builder.addCase(
      removeBillingAdmin.fulfilled,
      (state, action: AnyAction) => {
        state.billingAdminLoading = false;
        state.billingAdminSuccess =
          action?.payload?.message || "Billing Admin Removed";
        state.billingAdminError = false;
      }
    );
    builder.addCase(removeBillingAdmin.rejected, (state, action: AnyAction) => {
      state.billingAdminLoading = false;
      state.billingAdminSuccess = false;
      state.billingAdminError =
        action?.payload?.message || "Something went wrong";
    });
    builder.addCase(updateBillingAddress.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      updateBillingAddress.fulfilled,
      (state, action: AnyAction) => {
        state.loading = false;
        state.success = action?.payload?.message || "Billing Address Updated";
        state.error = false;
      }
    );
    builder.addCase(
      updateBillingAddress.rejected,
      (state, action: AnyAction) => {
        state.loading = false;
        state.success = false;
        state.error = action?.payload?.message || "Something went wrong";
      }
    );
    builder.addCase(getBillingAddress.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getBillingAddress.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.billingAddress = action?.payload;
      }
    );
    builder.addCase(getBillingAddress.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(removeUserOrUserLicense.pending, (state) => {
      state.userRemovalLoading = true;
      state.userRemovalSuccess = false;
      state.userRemovalError = false;
    });
    builder.addCase(
      removeUserOrUserLicense.fulfilled,
      (state, action: AnyAction) => {
        state.userRemovalLoading = false;
        state.userRemovalSuccess = action?.payload?.message || "User Removed";
        state.userRemovalError = false;
      }
    );
    builder.addCase(
      removeUserOrUserLicense.rejected,
      (state, action: AnyAction) => {
        state.userRemovalLoading = false;
        state.userRemovalSuccess = false;
        state.userRemovalError =
          action?.payload?.message || "Something went wrong";
      }
    );
    builder.addCase(removeBranchOrBranchLicense.pending, (state) => {
      state.branchRemovalLoading = true;
      state.branchRemovalSuccess = false;
      state.branchRemovalError = false;
    });
    builder.addCase(
      removeBranchOrBranchLicense.fulfilled,
      (state, action: AnyAction) => {
        state.branchRemovalLoading = false;
        state.branchRemovalSuccess =
          action?.payload?.message || "Branch Removed";
        state.branchRemovalError = false;
      }
    );
    builder.addCase(
      removeBranchOrBranchLicense.rejected,
      (state, action: AnyAction) => {
        state.branchRemovalLoading = false;
        state.branchRemovalSuccess = false;
        state.branchRemovalError =
          action?.payload?.message || "Something went wrong";
      }
    );
    builder.addCase(getPaymentHistoryByPagination.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getPaymentHistoryByPagination.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.paymentHistory = action?.payload;
      }
    );
    builder.addCase(
      getPaymentHistoryByPagination.rejected,
      (state, action: AnyAction) => {
        state.loading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getPaymentInvoice.pending, (state) => {
      state.loading = true;
      state.Invoicesuccess = false;
      state.error = false;
    });
    builder.addCase(
      getPaymentInvoice.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.Invoicesuccess = true;
        state.error = false;
        state.paymentInvoice = action?.payload;
      }
    );
    builder.addCase(getPaymentInvoice.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.Invoicesuccess = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(billingAutoDebit.pending, (state) => {
      state.autoDebitLoading = true;
      state.autoDebitSuccess = false;
      state.autoDebitError = false;
    });
    builder.addCase(billingAutoDebit.fulfilled, (state, action: AnyAction) => {
      state.autoDebitLoading = false;
      state.autoDebitSuccess = action?.payload?.message || "Payment Done";
      state.autoDebitError = false;
    });
    builder.addCase(billingAutoDebit.rejected, (state, action: AnyAction) => {
      state.autoDebitLoading = false;
      state.autoDebitSuccess = false;
      state.autoDebitError = action?.payload?.message || "Something went wrong";
    });
  },
});

export default billingSlice.reducer;
export const {
  reset,
  resetPromoCode,
  resetBillingSuccess,
  resetUserRemovalSuccess,
  resetBranchRemovalSuccess,
  resetPaymentError,
  resetPaymentInvoice,
  resetPromocodeSuccess,
  resetCancellation,
  resetAutoDebitStatuses,
} = billingSlice.actions;
