import { createAsyncThunk, AnyAction, createSlice } from "@reduxjs/toolkit";
import { DeactivateInboundDTO, DeactivateInboundData } from "./types";
import {
  DeactivateInbound,
  DownloadImportedHistory,
  GetImportHistory,
  GetImportHistoryDetails,
  GetMailConfigsList,
  WhatsappNotificationChange,
} from "./inbound.request";
import { GetWhatsappConfigInstitute } from "../Whatsapp/service/whatsapp.request";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: DeactivateInboundDTO = {
  inboundDeactivateError: false,
  inboundDeactivateSuccess: false,
  inboundDeactivateLoading: false,
  importHistorySuccess: false,
  importHistoryError: false,
  importHistoryLoading: false,
  importHistoryData: [],
  downloadHistoryDataSuccess: false,
  downloadHistoryDataError: false,
  downloadHistoryDataLoading: false,
  downloadHistoryData: [],
  whatsappNotificationSuccess: false,
  whatsappNotificationLoading: false,
  whatsappNotificationError: false,
  whatsappConfigInstituteSuccess: false,
  whatsappConfigInstituteLoading: false,
  whatsappConfigInstituteError: false,
  whatsappConfigInstituteData: [],
};

export const deactivateInbound = createAsyncThunk(
  "user/deactivateInbound",
  async (data: DeactivateInboundData, thunkAPI) => {
    return DeactivateInbound(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getMailConfigsList = createAsyncThunk(
  "user/getMailConfigsList",
  async (id: string, thunkAPI: any) => {
    return GetMailConfigsList(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getImportHistory = createAsyncThunk(
  "user/getImportHistory",
  async (
    data: {
      id: string;
      lastEvaluatedKey: string;
      limit: number;
      lastEvaluatedSortKey: string;
    },
    thunkAPI: any
  ) => {
    const { id, lastEvaluatedKey, limit, lastEvaluatedSortKey } = data;

    return GetImportHistory(id, lastEvaluatedKey, limit, lastEvaluatedSortKey)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const downloadHistoryData = createAsyncThunk(
  "user/downloadHistoryData",
  async (data: any, thunkAPI: any) => {
    return DownloadImportedHistory(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const whatsappNotificationChange = createAsyncThunk(
  "user/whatsappNotificationChange",
  async (data: any, thunkAPI: any) => {
    return WhatsappNotificationChange(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getWhatsappConfigInstitute = createAsyncThunk(
  "getWhatsappConfigInstitute",
  async (data: { id: string; status: string }, thunkAPI: any) => {
    const { id, status } = data;
    return GetWhatsappConfigInstitute({ id, status })
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const deactivateInboundSlice = createSlice({
  name: "inboundSettings",
  initialState,
  reducers: {
    reset: () => initialState,
    resetInboundDeactivateSuccess: (state) => {
      state.inboundDeactivateSuccess = false;
    },
    resetdownloadHistorySuccess: (state) => {
      state.downloadHistoryDataSuccess = false;
    },
    resetWhatsappNotificationChangeSuccess: (state) => {
      state.whatsappNotificationSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deactivateInbound.pending, (state) => {
      state.inboundDeactivateLoading = true;
      state.inboundDeactivateSuccess = false;
      state.inboundDeactivateError = false;
    });
    builder.addCase(deactivateInbound.fulfilled, (state, action: AnyAction) => {
      state.inboundDeactivateLoading = false;
      state.inboundDeactivateSuccess = true;
      state.inboundDeactivateError = false;
    });
    builder.addCase(deactivateInbound.rejected, (state, action: AnyAction) => {
      state.inboundDeactivateLoading = false;
      state.inboundDeactivateSuccess = false;
      state.inboundDeactivateError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getImportHistory.pending, (state) => {
      state.importHistoryLoading = true;
      state.importHistorySuccess = false;
      state.importHistoryError = false;
    });
    builder.addCase(getImportHistory.fulfilled, (state, action: AnyAction) => {
      state.importHistoryLoading = false;
      state.importHistorySuccess = true;
      state.importHistoryError = false;
      state.importHistoryData = action.payload;
    });
    builder.addCase(getImportHistory.rejected, (state, action: AnyAction) => {
      state.importHistoryLoading = false;
      state.importHistorySuccess = false;
      state.importHistoryError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(downloadHistoryData.pending, (state) => {
      state.downloadHistoryDataLoading = true;
      state.downloadHistoryDataSuccess = false;
      state.downloadHistoryDataError = false;
    });
    builder.addCase(
      downloadHistoryData.fulfilled,
      (state, action: AnyAction) => {
        state.downloadHistoryDataLoading = false;
        state.downloadHistoryDataSuccess = true;
        state.downloadHistoryDataError = false;
        state.downloadHistoryData = action.payload;
      }
    );
    builder.addCase(
      downloadHistoryData.rejected,
      (state, action: AnyAction) => {
        state.downloadHistoryDataLoading = false;
        state.downloadHistoryDataSuccess = false;
        state.downloadHistoryDataError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(whatsappNotificationChange.pending, (state) => {
      state.whatsappNotificationLoading = true;
      state.whatsappNotificationSuccess = false;
      state.whatsappNotificationError = false;
    });
    builder.addCase(
      whatsappNotificationChange.fulfilled,
      (state, action: AnyAction) => {
        state.whatsappNotificationLoading = false;
        state.whatsappNotificationSuccess = true;
        state.whatsappNotificationError = false;
      }
    );
    builder.addCase(
      whatsappNotificationChange.rejected,
      (state, action: AnyAction) => {
        state.whatsappNotificationLoading = false;
        state.whatsappNotificationSuccess = false;
        state.whatsappNotificationError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getWhatsappConfigInstitute.pending, (state) => {
      state.whatsappConfigInstituteLoading = true;
      state.whatsappConfigInstituteSuccess = false;
      state.whatsappConfigInstituteError = false;
    });
    builder.addCase(
      getWhatsappConfigInstitute.fulfilled,
      (state, action: AnyAction) => {
        state.whatsappConfigInstituteLoading = false;
        state.whatsappConfigInstituteSuccess = true;
        state.whatsappConfigInstituteError = false;
        state.whatsappConfigInstituteData = action?.payload;
      }
    );
    builder.addCase(
      getWhatsappConfigInstitute.rejected,
      (state, action: AnyAction) => {
        state.whatsappConfigInstituteLoading = false;
        state.whatsappConfigInstituteSuccess = false;
        state.whatsappConfigInstituteError =
          action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default deactivateInboundSlice.reducer;
export const {
  reset,
  resetInboundDeactivateSuccess,
  resetdownloadHistorySuccess,
  resetWhatsappNotificationChangeSuccess,
} = deactivateInboundSlice.actions;
