import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  CreateBranch,
  GetBranchById,
  GetListBranch,
  UpdateBranch,
} from "./Branch.request";
import { branchInitialStateDTO, createBranchDTO } from "./types";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: branchInitialStateDTO = {
  branchList: null,
  branchById: null,
  success: false,
  error: false,
  loading: false,
  branchListLoading: false,
  branchByIdLoading: false,
};

export const createBranch = createAsyncThunk(
  "user/branch",
  async (data: createBranchDTO, thunkAPI: any) => {
    return CreateBranch(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getBranchList = createAsyncThunk(
  "user/branchList",
  async (id: string, thunkAPI: any) => {
    return GetListBranch(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getBranchById = createAsyncThunk(
  "user/branchById",
  async (id: string, thunkAPI: any) => {
    return GetBranchById(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const updateBranch = createAsyncThunk(
  "user/updateBranch",
  async (data: createBranchDTO, thunkAPI: any) => {
    return UpdateBranch(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(createBranch.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(createBranch.fulfilled, (state, action: AnyAction) => {
      state.loading = false;
      state.success = action?.success?.message || "Branch Created";
      state.error = false;
    });
    builder.addCase(createBranch.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getBranchList.pending, (state) => {
      state.branchListLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getBranchList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.branchListLoading = false;
        state.success = true;
        state.error = false;
        state.branchList = action?.payload;
      }
    );
    builder.addCase(getBranchList.rejected, (state, action: AnyAction) => {
      state.branchListLoading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getBranchById.pending, (state) => {
      state.branchByIdLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getBranchById.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.branchByIdLoading = false;
        state.success = true;
        state.error = false;
        state.branchById = action?.payload;
      }
    );
    builder.addCase(getBranchById.rejected, (state, action: AnyAction) => {
      state.branchByIdLoading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });

    builder.addCase(updateBranch.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(updateBranch.fulfilled, (state, action: AnyAction) => {
      state.loading = false;
      state.success = action?.success?.message || "Branch Updated";
      state.error = false;
    });
    builder.addCase(updateBranch.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
  },
});

export default branchSlice.reducer;
export const { reset } = branchSlice.actions;
