import { axios } from "../../../../api/setup.intersepter";

import { requsestOTPDTO, resetPasswordDTO, verifyOTPDTO } from "./types";

export const ForgetPasswordOTPRequest = (body: requsestOTPDTO) => {
  return axios({
    method: "POST",
    url: `/resetPassword`,
    data: body,
  });
};
export const ForgetPasswordVerifyOTPRequest = (body: verifyOTPDTO) => {
  return axios({
    method: "POST",
    url: `/verifyOtp`,
    data: body,
  });
};
export const ForgetPasswordResetPasswordRequest = (body: resetPasswordDTO) => {
  return axios({
    method: "POST",
    url: `/setPassword`,
    data: body,
  });
};
