import React, { lazy, Suspense } from "react";
import { useIsAuthenticated, useAuthUser } from "react-auth-kit";
import { Route, Routes, Navigate, useSearchParams } from "react-router-dom";

// import Main from "../pages/app/Main/Main";

import GoogleAuthRedirect from "../pages/auth/gauth/gauth";
import { CircularProgress } from "@mui/material";

const Main = lazy(() => import("../pages/app/Main/Main"));

const ForgetPassword = lazy(() =>
  import("../pages").then((module) => ({ default: module.ForgetPassword }))
);
const NotFound = lazy(() =>
  import("../pages").then((module) => ({ default: module.NotFound }))
);
const PrivacyPolicy = lazy(() =>
  import("../pages").then((module) => ({ default: module.PrivacyPolicy }))
);

const SignIn = lazy(() =>
  import("../pages").then((module) => ({ default: module.SignIn }))
);
const Signup = lazy(() =>
  import("../pages").then((module) => ({ default: module.Signup }))
);
const TermsOfServices = lazy(() =>
  import("../pages").then((module) => ({ default: module.TermsOfServices }))
);
const Campaign = lazy(() =>
  import("../pages").then((module) => ({ default: module.Campaign }))
);
const PublicWebForm = lazy(() =>
  import("../pages").then((module) => ({ default: module.PublicWebForm }))
);

function Router() {
  const auth = useAuthUser();
  const authUser = auth();
  const isAuthenticated = useIsAuthenticated();

  function PrivateRoute(props: any) {
    const { children } = props;
    return isAuthenticated() ? children : <Navigate to="/" />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<CircularProgress />}>
            <SignIn />
          </Suspense>
        }
      />
      <Route
        path="/signup"
        element={
          <Suspense fallback={<CircularProgress />}>
            <Signup />
          </Suspense>
        }
      />
      <Route
        path="/forget-password"
        element={
          <Suspense fallback={<CircularProgress />}>
            <ForgetPassword />
          </Suspense>
        }
      />
      <Route
        path="/app/*"
        element={
          <PrivateRoute>
            <Suspense fallback={<CircularProgress />}>
              <Main />
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path="/campaign"
        element={
          <Suspense fallback={<CircularProgress />}>
            <Campaign />
          </Suspense>
        }
      />
      <Route
        path="/publicwebform/:id"
        element={
          <Suspense fallback={<CircularProgress />}>
            <PublicWebForm />
          </Suspense>
        }
      />
      <Route
        path="/terms-of-services"
        element={
          <Suspense fallback={<CircularProgress />}>
            <TermsOfServices />
          </Suspense>
        }
      />
      <Route
        path="/gauth"
        element={
          <Suspense fallback={<CircularProgress />}>
            <GoogleAuthRedirect />
          </Suspense>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <Suspense fallback={<CircularProgress />}>
            <PrivacyPolicy />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<CircularProgress />}>
            <NotFound />
          </Suspense>
        }
      />
    </Routes>
  );
}

export default Router;
