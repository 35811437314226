import { axios_institute_public } from "../../../../../../api/setup.intersepter";

import { PublicWebformStateDTO } from "./types";

export const CreateContact = (data: any) => {
  return axios_institute_public({
    method: "POST",
    url: `/studentContact`,
    data: data,
  });
};
// export const UpdateContact = (data: any) => {
//   return axios_institute_public({
//     method: "PUT",
//     url: `/studentContact?studentContactId=${data.id}&demoTiming=${data.demoTiming}`,
//   });
// };
export const GetPublicWebformById = (data: any) => {
  return axios_institute_public({
    method: "GET",
    url: `/getWebformById?webFormID=${data}`,
  });
};
