import { createAsyncThunk, AnyAction, createSlice } from "@reduxjs/toolkit";
import { WhatsAppInitialStateDTO, WhatsappConfigDTO } from "./types";
import {
  ConfigWhatsapp,
  DeleteWhatsapp,
  GetWaConfig,
  GetWhatsappConfigInstitute,
} from "./whatsapp.request";
import { GetMailConfigsList } from "../../service/inbound.request";

export const initialState: WhatsAppInitialStateDTO = {
  success: false,
  error: false,
  loading: false,
  gupshupConfig: "",
  gupshupConfigSuccess: false,
  gupshupConfigLoading: false,
  gupshupConfigError: false,
  mailConfigsList: null,
  mailConfigListSuccess: false,
  mailConfigListError: false,
  mailConfigListLoading: false,
  deleteGupshupSuccess: false,
  deleteGupshupLoading: false,
  deleteGupshupError: false,
};

export const getMailConfigsList = createAsyncThunk(
  "user/getMailConfigsList",
  async (id: string, thunkAPI: any) => {
    return GetMailConfigsList(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getWhatsappConfig = createAsyncThunk<void, void>(
  "getWhatsappConfig",
  async (thunkAPI: any) => {
    return GetWaConfig()
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const configWhatsapp = createAsyncThunk(
  "configWhatsapp",
  async (data: WhatsappConfigDTO, thunkAPI: any) => {
    return ConfigWhatsapp(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getWhatsappConfigInstitute = createAsyncThunk(
  "getWhatsappConfigInstitute",
  async (data: { id: string; status: string }, thunkAPI: any) => {
    const { id, status } = data;
    return GetWhatsappConfigInstitute({ id, status })
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const deleteGupShup = createAsyncThunk(
  "deleteGupShup",
  async (data:any, thunkAPI: any) => {
    return DeleteWhatsapp(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const whatsappSlice = createSlice({
  name: "whatsapp",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getWhatsappConfig.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(getWhatsappConfig.fulfilled, (state, action: AnyAction) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.gupshupConfig = action?.payload;
    });
    builder.addCase(getWhatsappConfig.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(configWhatsapp.pending, (state) => {
      state.gupshupConfigLoading = true;
      state.gupshupConfigSuccess = false;
      state.gupshupConfigError = false;
    });
    builder.addCase(configWhatsapp.fulfilled, (state, action: AnyAction) => {
      state.gupshupConfigLoading = false;
      state.gupshupConfigSuccess = true;
      state.gupshupConfigError = false;
    });
    builder.addCase(configWhatsapp.rejected, (state, action: AnyAction) => {
      state.gupshupConfigLoading = false;
      state.gupshupConfigSuccess = false;
      state.gupshupConfigError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getWhatsappConfigInstitute.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getWhatsappConfigInstitute.fulfilled,
      (state, action: AnyAction) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.gupshupConfig = action?.payload;
      }
    );
    builder.addCase(
      getWhatsappConfigInstitute.rejected,
      (state, action: AnyAction) => {
        state.loading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getMailConfigsList.pending, (state) => {
      state.mailConfigListLoading = true;
      state.mailConfigListSuccess = false;
      state.mailConfigListError = false;
    });
    builder.addCase(
      getMailConfigsList.fulfilled,
      (state, action: AnyAction) => {
        state.mailConfigListLoading = false;
        state.mailConfigListSuccess = true;
        state.mailConfigListError = false;
        state.mailConfigsList = action?.payload;
      }
    );
    builder.addCase(getMailConfigsList.rejected, (state, action: AnyAction) => {
      state.mailConfigListLoading = false;
      state.mailConfigListSuccess = false;
      state.mailConfigListError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(deleteGupShup.pending, (state) => {
      state.deleteGupshupLoading = true;
      state.deleteGupshupSuccess = false;
      state.deleteGupshupError = false;
    });
    builder.addCase(
      deleteGupShup.fulfilled,
      (state, action: AnyAction) => {
        state.deleteGupshupLoading = false;
        state.deleteGupshupSuccess = true;
        state.deleteGupshupError = false;
      }
    );
    builder.addCase(
      deleteGupShup.rejected,
      (state, action: AnyAction) => {
        state.deleteGupshupLoading = false;
        state.deleteGupshupSuccess = false;
        state.deleteGupshupError = action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default whatsappSlice.reducer;
export const { reset } = whatsappSlice.actions;
