import { useEffect, useState } from "react";
import { useSignIn, useIsAuthenticated, useAuthUser } from "react-auth-kit";
import { useNavigate, useLocation } from "react-router-dom";
import { revokeSession } from "./signin/service/signinSlice";
import { useDispatch } from "react-redux";
import { RevokeSessionDTO, LoginResponseData } from "./signin/service/types";
import CompareTncPrivacyPolicy from "../../components/TncComparision/TncComparision";
import { getTnc } from "../app/TermsOfServices/service/termsofservices.request";

function AutoLogin() {
  const signIn = useSignIn();
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const auth = useAuthUser();
  const user = auth();
  const [tnc, setTnc] = useState<any>();

  useEffect(() => {
    getTnc()
      .then((res: any) => {
        setTnc(res?.data?.data);
      })
      .catch((err: any) => {});
  }, []);

  const storeDataInLocalStorage = async (data: LoginResponseData) => {
    try {
      localStorage.setItem("ACTIVE_INSTITUTE", JSON.stringify(data));
    } catch (e) {}
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get("token");
    const isVersionsMatching = CompareTncPrivacyPolicy(user);

    if (token) {
      const tokenBody: RevokeSessionDTO = {
        auth_token: token,
      };
      dispatch<any>(revokeSession(tokenBody))
        .then((response: any) => {
          if (window.location.hostname === "localhost") {
            if (
              response.payload.data.data.institute[0].instituteDetails
                .isProfileCompleted
            ) {
              navigate(`/app/dashboard`, {
                state: {
                  tnc: tnc && tnc,
                  isVersionMatching: isVersionsMatching,
                },
              });
            } else if (
              !response.payload.data.data.institute[0].instituteDetails
                .isProfileCompleted
            ) {
              navigate(`/app/home`, {
                state: {
                  tnc: tnc && tnc,
                  isVersionMatching: isVersionsMatching,
                },
              });
            }
          } else if (
            window.location.hostname ===
            response.payload.data.data.institute[0].instituteDetails.appURL
          ) {
            storeDataInLocalStorage(
              response.payload.data.data.institute[0]?.instituteDetails
            );
            signIn({
              authState: response.payload.data.data,
              token: response.payload.data.data.token,
              expiresIn: 1439,
              tokenType: "Bearer",
            });
            if (
              response.payload.data.data.institute[0].instituteDetails
                .isProfileCompleted
            ) {
              navigate(`/app/dashboard`, {
                state: {
                  tnc: tnc && tnc,
                  isVersionMatching: isVersionsMatching,
                },
              });
            } else if (
              !response.payload.data.data.institute[0].instituteDetails
                .isProfileCompleted
            ) {
              navigate(`/app/home`, {
                state: {
                  tnc: tnc && tnc,
                  isVersionMatching: isVersionsMatching,
                },
              });
            }
          } else {
            window.location.href = `https://${response.payload.data.data.institute[0].instituteDetails.appURL}?token=${response.payload.data.data.token}`;
          }
        })
        .catch((error: any) => {
          console.error("RevokeSession error", error);
        });
    } else {
      // Redirect to login page if there is no token
    }
  }, [signIn, navigate, location, isAuthenticated, dispatch]);

  // This component does not render anything
  return null;
}

export default AutoLogin;
