import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { getTnc } from "./termsofservices.request";
import { TncDTO, TncInitialStateDTO } from "./types";

export const initialState: TncInitialStateDTO = {
  success: false,
  error: false,
  loading: false,
  tnc: "",
};

export const GetTnc = createAsyncThunk("user/tnc", () => {
  return getTnc()
    .then((response: any) => {
      return response?.data?.data;
    })
    .catch(function (e: any) {
      throw e.response.data;
    });
});

export const tncSlice = createSlice({
  name: "tnc",
  initialState,
  reducers: {
    reset: () => initialState,
    setConfigData: (state, action: PayloadAction<TncDTO>) => {
      return { ...state, data: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetTnc.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(GetTnc.fulfilled, (state, action: AnyAction) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.tnc = action?.payload;
    });
    builder.addCase(GetTnc.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
  },
});

export default tncSlice.reducer;
export const { reset, setConfigData } = tncSlice.actions;
