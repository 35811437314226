import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function GoogleAuthRedirect() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");
    const from = urlParams.get("state"); // Get the origin subdomain from the state param
    const error = urlParams.get("error");
    if (code && from) {
      // In a real-world scenario, here you should exchange and verify the code in your backend
      // Once the code is verified, redirect back to the original subdomain
      window.location.href = `${from}?code=${code}`;
    } else if (error === "access_denied") {
      // No code found, redirect back to main domain or show an error
      const newFromUrl = from?.replace(
        "/EmailIntegration/EmailSyncing",
        "/Email"
      );
      window.location.href = `${newFromUrl}`;
      // window.location.href = `${from}`;
      // navigate("/app/Inbound/Email");
    }
  }, [navigate, location]);

  // While the redirect is being processed you can show a loading screen
  return <div>Loading...</div>;
}

export default GoogleAuthRedirect;
