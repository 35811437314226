import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { getRegistrationQuery } from "./home.request";
import { AppEntryInitialStateDTO, AppEntryPayloadDTO } from "./types";

export const initialState: AppEntryInitialStateDTO = {
  error: false,
  loading: false,
  success: false,
  data: null,
  profileDetailsCompleted: false,
  isProfileComplete: false,
  isCourseSetup: false,
  isBranchAdded: false,
  isTeamAdded: false,
  isBatchAdded: false,
};

export const appEntery = createAsyncThunk("app/home", (data: string) => {
  return getRegistrationQuery(data)
    .then((response: any) => {
      if (response.status === 200) {
        localStorage.setItem(
          "ACTIVE_INSTITUTE",
          JSON.stringify(response?.data?.data)
        );
      }
      return response?.data?.data;
    })
    .catch((error: any) => {
      throw error.response.data;
    });
});

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(appEntery.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    });
    builder.addCase(
      appEntery.fulfilled,
      (state, action: PayloadAction<AppEntryPayloadDTO>) => {
        state.loading = false;
        state.error = false;
        state.success = true;
        state.profileDetailsCompleted = action.payload.profileDetailsCompleted;
        state.isBatchAdded = action.payload.batchCreated;
        state.isBranchAdded = action.payload.branchCreated;
        state.isProfileComplete = action.payload.isProfileCompleted;
        state.isCourseSetup = action.payload.courseCreated;
        state.isTeamAdded = action.payload.teamAdded;
        state.data = action.payload;
      }
    );
    builder.addCase(appEntery.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
  },
});
export default homeSlice.reducer;
export const { reset } = homeSlice.actions;
