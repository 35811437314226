import { axios_institute_private } from "../../../../api/setup.intersepter";
import {
  UpdateTaskAssigneeDTO,
  UpdateTaskPriorityDTO,
  UpdateTaskStatusDTO,
} from "./types";

export const GetAllTaskByAssignee = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/task?userId=${id}&type=ALL`,
  });
};

export const GetAllTaskType = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: "taskType?status=ALL",
  });
};

export const UpdateTaskStatus = (body: UpdateTaskStatusDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/task/updateTaskStatus`,
    data: body,
  });
};
export const GetTaskBoard = (body: any) => {
  return axios_institute_private({
    method: "POST",
    url: `task/paginate`,
    data: body,
  });
};

// export const GetTaskBoard = (
//   id: string,
//   startDate: string,
//   endDate: string,
//   taskType: string,
//   priority: string
// ) => {
//   return axios_institute_private({
//     method: "GET",
//     url: `task/getTaskBoardByUser?userId=${id}&startDate=${startDate}&endDate=${endDate}&taskType=All&taskPriority=All`,
//   });
// };

export const GetTaskById = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/task?taskId=${id}`,
  });
};

export const UpdateTaskPriority = (body: UpdateTaskPriorityDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/task/updateTaskPriority `,
    data: body,
  });
};

export const UpdateTaskAssignee = (body: UpdateTaskAssigneeDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/task/updateTaskAssignee `,
    data: body,
  });
};

export const GetAllTaskByDateForAssignee = (data: any) => {
  return axios_institute_private({
    method: "GET",
    url: `/task?userId=${data.id}&type=${data.taskType}&startDate=${data.startDate}&endDate=${data.endDate}`,
  });
};
