import { axios_institute_private } from "../../../../../api/setup.intersepter";

import { CreateCategoryDTO } from "./types";

export const CreateCategoryRequest = (body: CreateCategoryDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/courseCategory`,
    data: body,
  });
};
