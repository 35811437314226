import { AnyAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CheckSubDomainRequest,
  CreateInstitute,
  CreateInstitutesetPasswordRequest,
  CreateInstituteVerifyOTPRequest,
} from "./Signup.request";
import {
  createInstituteDTO,
  createInstituteInitialStateDTO,
  createInstituteVerifyOTPDTO,
  setPasswordDTO,
} from "./types";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: createInstituteInitialStateDTO = {
  loading_createInstitute: false,
  success_createInstitute: false,
  error_createInstitute: false,
  loading_createInstitute_verifyOTP: false,
  success_createInstitute_verifyOTP: false,
  error_createInstitute_verifyOTP: false,
  loading_createInstitute_setPassword: false,
  success_createInstitute_setPassword: false,
  error_createInstitute_setPassword: false,
  loading_checkSubDomain: false,
  success_checkSubDomain: false,
  error_checkSubDomain: false,
};

export const createInstitute = createAsyncThunk(
  "user/signUp",
  async (data: createInstituteDTO, thunkAPI: any) => {
    return CreateInstitute(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const createInstituteVerifyOTP = createAsyncThunk(
  "user/signUp/verifyOTP",
  async (data: createInstituteVerifyOTPDTO, thunkAPI: any) => {
    return CreateInstituteVerifyOTPRequest(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const setPassword = createAsyncThunk(
  "user/signUp/setPassword",
  async (data: setPasswordDTO, thunkAPI: any) => {
    return CreateInstitutesetPasswordRequest(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const checkSubDomain = createAsyncThunk(
  "institutions/subdomain",
  async (data: string, thunkAPI: any) => {
    return CheckSubDomainRequest(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const signupSlice = createSlice({
  name: "signUp",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(createInstitute.pending, (state) => {
      state.loading_createInstitute = true;
      state.success_createInstitute = false;
      state.error_createInstitute = false;
    });
    builder.addCase(createInstitute.fulfilled, (state) => {
      state.loading_createInstitute = false;
      state.success_createInstitute = true;
      state.error_createInstitute = false;
    });
    builder.addCase(createInstitute.rejected, (state, action: AnyAction) => {
      state.loading_createInstitute = false;
      state.success_createInstitute = false;
      state.error_createInstitute =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(createInstituteVerifyOTP.pending, (state) => {
      state.loading_createInstitute_verifyOTP = true;
      state.success_createInstitute_verifyOTP = false;
      state.error_createInstitute_verifyOTP = false;
    });
    builder.addCase(createInstituteVerifyOTP.fulfilled, (state) => {
      state.loading_createInstitute_verifyOTP = false;
      state.success_createInstitute_verifyOTP = true;
      state.error_createInstitute_verifyOTP = false;
    });
    builder.addCase(
      createInstituteVerifyOTP.rejected,
      (state, action: AnyAction) => {
        state.loading_createInstitute_verifyOTP = false;
        state.success_createInstitute_verifyOTP = false;
        state.error_createInstitute_verifyOTP =
          action?.error?.message || "Something went wrong";
      }
    );

    builder.addCase(setPassword.pending, (state) => {
      state.loading_createInstitute_setPassword = true;
      state.success_createInstitute_setPassword = false;
      state.error_createInstitute_setPassword = false;
    });
    builder.addCase(setPassword.fulfilled, (state) => {
      state.loading_createInstitute_setPassword = false;
      state.success_createInstitute_setPassword = true;
      state.error_createInstitute_setPassword = false;
    });
    builder.addCase(setPassword.rejected, (state, action: AnyAction) => {
      state.loading_createInstitute_setPassword = false;
      state.success_createInstitute_setPassword = false;
      state.error_createInstitute_setPassword =
        action?.error?.message || "Something went wrong";
    });

    builder.addCase(checkSubDomain.pending, (state) => {
      state.loading_checkSubDomain = true;
      state.success_checkSubDomain = false;
      state.error_checkSubDomain = false;
    });
    builder.addCase(checkSubDomain.fulfilled, (state) => {
      state.loading_checkSubDomain = false;
      state.success_checkSubDomain = true;
      state.error_checkSubDomain = false;
    });
    builder.addCase(checkSubDomain.rejected, (state, action: AnyAction) => {
      state.loading_checkSubDomain = false;
      state.success_checkSubDomain = false;
      state.error_checkSubDomain =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default signupSlice.reducer;
export const { reset } = signupSlice.actions;
