import { createAsyncThunk, AnyAction, createSlice } from "@reduxjs/toolkit";
import {
  WebformCustomfieldDTO,
  WebformDTO,
  WebformInitialStateDTO,
} from "./types";
import {
  BulkLeadCreation,
  CreateCustomFieldWebform,
  CreateWebform,
  DeleteWebform,
  DeleteWebformResponse,
  GetAllWebforms,
  GetPrimaryFieldsByInstitute,
  GetPrimaryFieldsByInstituteForBranch,
  GetStudentContactById,
  GetWebformByInstitute,
  GetWebformResponse,
  UpdateWebform,
} from "./webform.request";
import { GetMailConfigsList } from "../../service/inbound.request";
import { GetNurtureLeadBoard } from "../../../Nurture/service/nurture.request";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: WebformInitialStateDTO = {
  CreateWebformSuccess: false,
  CreateWebformError: false,
  CreateWebformLoading: false,
  UpdateWebformSuccess: false,
  UpdateWebformError: false,
  UpdateWebformLoading: false,
  CreateCustomFieldWebformSuccess: false,
  CreateCustomFieldWebformError: false,
  CreateCustomFieldWebformLoading: false,
  GetAllWebformSuccess: false,
  GetAllWebformError: false,
  GetAllWebformLoading: false,
  getAllFormByInstituteData: null,
  CreateWebformId: null,
  getFormByInstituteSuccess: false,
  getFormByInstituteLoading: false,
  getFormByInstituteError: false,
  getFormByInstituteData: null,
  getPrimaryFieldsSuccess: false,
  getPrimaryFieldsLoading: false,
  getPrimaryFieldsError: false,
  getPrimaryFieldsData: null,
  getFormResponseSuccess: false,
  getFormResponseLoading: false,
  getFormResponseError: false,
  getFormResponseData: null,
  getStudentContactByIdLoading: false,
  getStudentContactByIdError: false,
  getStudentContactByIdSuccess: false,
  getStudentContactByIdData: null,
  bulkLeadCreationSuccess: false,
  bulkLeadCreationLoading: false,
  bulkLeadCreationError: false,
  deleteWebformResponseSuccess: false,
  deleteWebformResponseLoading: false,
  deleteWebformResponseError: false,
  deleteWebformSuccess: false,
  deleteWebformLoading: false,
  deleteWebformError: false,
};

export const createWebform = createAsyncThunk(
  "createWebform",
  async (data: WebformDTO, thunkAPI: any) => {
    return CreateWebform(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const updateWebform = createAsyncThunk(
  "updateWebform",
  async (data: WebformDTO, thunkAPI: any) => {
    return UpdateWebform(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);
export const createCustomFieldWebform = createAsyncThunk(
  "createCustomFieldWebform",
  async (data: WebformCustomfieldDTO, thunkAPI: any) => {
    return CreateCustomFieldWebform(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getAllWebForm = createAsyncThunk("getAllWebForm", (data: any) => {
  return GetAllWebforms(data)
    .then((response: any) => {
      return response?.data?.data;
    })
    .catch((error: any) => {
      throw error.response.data;
    });
});

// export const getAllWebForm = createAsyncThunk(
//   "getAllWebForm",
//   async (
//     data: {
//       id: string;
//       lastEvaluatedKey: string;
//       limit: number;
//       lastEvaluatedSortKey: string;
//       roleInfo: any;
//       formType: any;
//     },
//     thunkAPI: any
//   ) => {
//     const {
//       id,
//       lastEvaluatedKey,
//       limit,
//       lastEvaluatedSortKey,
//       roleInfo,
//       formType,
//     } = data;
//     return GetAllWebforms(
//       id,
//       lastEvaluatedKey,
//       limit,
//       lastEvaluatedSortKey,
//       roleInfo,
//       formType
//     )
//       .then((response: any) => {
//         return response?.data?.data;
//       })
//       .catch(function (e: any) {
//         throw e.response.data;
//       });
//   }
// );

export const getWebformByInstitute = createAsyncThunk(
  "getWebformByInstitute",
  async (data: any, thunkAPI: any) => {
    const { instituteId, webformId } = data;
    return GetWebformByInstitute({ instituteId, webformId })
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getPrimaryFieldsByInstitute = createAsyncThunk(
  "getPrimaryFieldsByInstitute",
  async (data: any, thunkAPI: any) => {
    const { id, lastEvaluatedKey, limit, lastEvaluatedSortKey } = data;
    return GetPrimaryFieldsByInstitute(
      id,
      lastEvaluatedKey,
      limit,
      lastEvaluatedSortKey
    )
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getPrimaryFieldsByInstituteForBranch = createAsyncThunk(
  "getPrimaryFieldsByInstituteForBranch",
  async (data: any, thunkAPI: any) => {
    const { id, lastEvaluatedKey, limit, lastEvaluatedSortKey, branchId } =
      data;
    return GetPrimaryFieldsByInstituteForBranch(
      id,
      lastEvaluatedKey,
      limit,
      lastEvaluatedSortKey,
      branchId
    )
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getWebformResponse = createAsyncThunk(
  "getWebformResponse",
  (data: any) => {
    return GetWebformResponse(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch((error: any) => {
        throw error.response.data;
      });
  }
);

// export const getWebformResponse = createAsyncThunk(
//   "getWebformResponse",
//   async (data: any, thunkAPI: any) => {
//     const {
//       id,
//       lastEvaluatedKey,
//       limit,
//       lastEvaluatedSortKey,
//       formId,
//       roleInfo,
//     } = data;
//     return GetWebformResponse(
//       id,
//       lastEvaluatedKey,
//       limit,
//       lastEvaluatedSortKey,
//       formId,
//       roleInfo
//     )
//       .then((response: any) => {
//         return response?.data?.data;
//       })
//       .catch(function (e: any) {
//         throw e.response.data;
//       });
//   }
// );

export const getStudentContactById = createAsyncThunk(
  "getStudentContactById",
  async (id: any, thunkAPI: any) => {
    return GetStudentContactById(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const bulkLeadCreation = createAsyncThunk(
  "bulkLeadCreation",
  async (data: any, thunkAPI: any) => {
    return BulkLeadCreation(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const deleteWebformResponse = createAsyncThunk(
  "deleteWebformResponse",
  async (data: any, thunkAPI: any) => {
    return DeleteWebformResponse(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const deleteWebform = createAsyncThunk(
  "deleteWebform",
  async (id: string, thunkAPI: any) => {
    return DeleteWebform(id)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const webformSlice = createSlice({
  name: "webform",
  initialState,
  reducers: {
    reset: () => initialState,
    resetUpdateWebformSuccess: (state) => {
      state.UpdateWebformSuccess = false;
    },
    resetCustomWebformFieldSuccess: (state) => {
      state.CreateCustomFieldWebformSuccess = false;
    },
    resetCreateWebformSuccess: (state) => {
      state.CreateWebformSuccess = false;
    },
    resetBulkLeadCreationSuccess: (state) => {
      state.bulkLeadCreationSuccess = false;
    },
    resetDeleteWebformResponseSuccess: (state) => {
      state.deleteWebformResponseSuccess = false;
    },
    resetSingleFormData: (state) => {
      state.getFormByInstituteData = null;
      state.getFormResponseData = null;
    },
    resetBulkLeadDeleteSuccess: (state) => {
      state.deleteWebformResponseSuccess = false;
    },
    resetDeleteWebformSuccess: (state) => {
      state.deleteWebformSuccess = false;
    },
    resetAllWebforms: (state) => {
      state.getAllFormByInstituteData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createWebform.pending, (state) => {
      state.CreateWebformLoading = true;
      state.CreateWebformSuccess = false;
      state.CreateWebformError = false;
    });
    builder.addCase(createWebform.fulfilled, (state, action: AnyAction) => {
      state.CreateWebformLoading = false;
      state.CreateWebformSuccess = true;
      state.CreateWebformError = false;
      state.CreateWebformId = action?.payload;
    });
    builder.addCase(createWebform.rejected, (state, action: AnyAction) => {
      state.CreateWebformLoading = false;
      state.CreateWebformSuccess = false;
      state.CreateWebformError =
        action?.error?.message || "Something went wrong";
    });

    builder.addCase(updateWebform.pending, (state) => {
      state.UpdateWebformLoading = true;
      state.UpdateWebformSuccess = false;
      state.UpdateWebformError = false;
    });
    builder.addCase(updateWebform.fulfilled, (state, action: any) => {
      state.UpdateWebformLoading = false;
      state.UpdateWebformSuccess = action;
      state.UpdateWebformError = false;
    });
    builder.addCase(updateWebform.rejected, (state, action: AnyAction) => {
      state.UpdateWebformLoading = false;
      state.UpdateWebformSuccess = false;
      state.UpdateWebformError =
        action?.error?.message || "Something went wrong";
    });

    builder.addCase(createCustomFieldWebform.pending, (state) => {
      state.CreateCustomFieldWebformLoading = true;
      state.CreateCustomFieldWebformSuccess = false;
      state.CreateCustomFieldWebformError = false;
    });
    builder.addCase(
      createCustomFieldWebform.fulfilled,
      (state, action: AnyAction) => {
        state.CreateCustomFieldWebformLoading = false;
        state.CreateCustomFieldWebformSuccess = true;
        state.CreateCustomFieldWebformError = false;
      }
    );
    builder.addCase(
      createCustomFieldWebform.rejected,
      (state, action: AnyAction) => {
        state.CreateCustomFieldWebformLoading = false;
        state.CreateCustomFieldWebformSuccess = false;
        state.CreateCustomFieldWebformError =
          action?.error?.message || "Something went wrong";
      }
    );

    builder.addCase(getAllWebForm.pending, (state) => {
      state.GetAllWebformLoading = true;
      state.GetAllWebformSuccess = false;
      state.GetAllWebformError = false;
    });
    builder.addCase(getAllWebForm.fulfilled, (state, action: AnyAction) => {
      state.GetAllWebformLoading = false;
      state.GetAllWebformSuccess = true;
      state.GetAllWebformError = false;
      state.getAllFormByInstituteData = action?.payload;
    });
    builder.addCase(getAllWebForm.rejected, (state, action: AnyAction) => {
      state.GetAllWebformLoading = false;
      state.GetAllWebformSuccess = false;
      state.GetAllWebformError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getWebformByInstitute.pending, (state) => {
      state.getFormByInstituteLoading = true;
      state.getFormByInstituteSuccess = false;
      state.getFormByInstituteError = false;
    });
    builder.addCase(
      getWebformByInstitute.fulfilled,
      (state, action: AnyAction) => {
        state.getFormByInstituteLoading = false;
        state.getFormByInstituteSuccess = true;
        state.getFormByInstituteError = false;
        state.getFormByInstituteData = action?.payload;
      }
    );
    builder.addCase(
      getWebformByInstitute.rejected,
      (state, action: AnyAction) => {
        state.getFormByInstituteLoading = false;
        state.getFormByInstituteSuccess = false;
        state.getFormByInstituteError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getPrimaryFieldsByInstitute.pending, (state) => {
      state.getPrimaryFieldsLoading = true;
      state.getPrimaryFieldsSuccess = false;
      state.getPrimaryFieldsError = false;
    });
    builder.addCase(
      getPrimaryFieldsByInstitute.fulfilled,
      (state, action: AnyAction) => {
        state.getPrimaryFieldsLoading = false;
        state.getPrimaryFieldsSuccess = true;
        state.getPrimaryFieldsError = false;
        state.getPrimaryFieldsData = action?.payload;
      }
    );
    builder.addCase(
      getPrimaryFieldsByInstitute.rejected,
      (state, action: AnyAction) => {
        state.getPrimaryFieldsLoading = false;
        state.getPrimaryFieldsSuccess = false;
        state.getPrimaryFieldsError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getWebformResponse.pending, (state) => {
      state.getFormResponseLoading = true;
      state.getFormResponseSuccess = false;
      state.getFormResponseError = false;
    });
    builder.addCase(
      getWebformResponse.fulfilled,
      (state, action: AnyAction) => {
        state.getFormResponseLoading = false;
        state.getFormResponseSuccess = true;
        state.getFormResponseError = false;
        state.getFormResponseData = action?.payload;
      }
    );
    builder.addCase(getWebformResponse.rejected, (state, action: AnyAction) => {
      state.getFormResponseLoading = false;
      state.getFormResponseSuccess = false;
      state.getFormResponseError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getStudentContactById.pending, (state) => {
      state.getStudentContactByIdLoading = true;
      state.getStudentContactByIdSuccess = false;
      state.getStudentContactByIdError = false;
    });
    builder.addCase(
      getStudentContactById.fulfilled,
      (state, action: AnyAction) => {
        state.getStudentContactByIdLoading = false;
        state.getStudentContactByIdSuccess = true;
        state.getStudentContactByIdError = false;
        state.getStudentContactByIdData = action?.payload;
      }
    );
    builder.addCase(
      getStudentContactById.rejected,
      (state, action: AnyAction) => {
        state.getStudentContactByIdLoading = false;
        state.getStudentContactByIdSuccess = false;
        state.getStudentContactByIdError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(bulkLeadCreation.pending, (state) => {
      state.bulkLeadCreationLoading = true;
      state.bulkLeadCreationSuccess = false;
      state.bulkLeadCreationError = false;
    });
    builder.addCase(bulkLeadCreation.fulfilled, (state, action: any) => {
      state.bulkLeadCreationLoading = false;
      state.bulkLeadCreationSuccess = action?.payload?.message;
      state.bulkLeadCreationError = false;
    });
    builder.addCase(bulkLeadCreation.rejected, (state, action: AnyAction) => {
      state.bulkLeadCreationLoading = false;
      state.bulkLeadCreationSuccess = false;
      state.bulkLeadCreationError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(deleteWebformResponse.pending, (state) => {
      state.deleteWebformResponseLoading = true;
      state.deleteWebformResponseSuccess = false;
      state.deleteWebformResponseError = false;
    });
    builder.addCase(
      deleteWebformResponse.fulfilled,
      (state, action: AnyAction) => {
        state.deleteWebformResponseLoading = false;
        state.deleteWebformResponseSuccess = true;
        state.deleteWebformResponseError = false;
      }
    );
    builder.addCase(
      deleteWebformResponse.rejected,
      (state, action: AnyAction) => {
        state.deleteWebformResponseLoading = false;
        state.deleteWebformResponseSuccess = false;
        state.deleteWebformResponseError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(deleteWebform.pending, (state) => {
      state.deleteWebformLoading = true;
      state.deleteWebformSuccess = false;
      state.deleteWebformError = false;
    });
    builder.addCase(deleteWebform.fulfilled, (state, action: AnyAction) => {
      state.deleteWebformLoading = false;
      state.deleteWebformSuccess = true;
      state.deleteWebformError = false;
    });
    builder.addCase(deleteWebform.rejected, (state, action: AnyAction) => {
      state.deleteWebformLoading = false;
      state.deleteWebformSuccess = false;
      state.deleteWebformError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getPrimaryFieldsByInstituteForBranch.pending, (state) => {
      state.getPrimaryFieldsLoading = true;
      state.getPrimaryFieldsSuccess = false;
      state.getPrimaryFieldsError = false;
    });
    builder.addCase(
      getPrimaryFieldsByInstituteForBranch.fulfilled,
      (state, action: AnyAction) => {
        state.getPrimaryFieldsLoading = false;
        state.getPrimaryFieldsSuccess = true;
        state.getPrimaryFieldsError = false;
        state.getPrimaryFieldsData = action?.payload;
      }
    );
    builder.addCase(
      getPrimaryFieldsByInstituteForBranch.rejected,
      (state, action: AnyAction) => {
        state.getPrimaryFieldsLoading = false;
        state.getPrimaryFieldsSuccess = false;
        state.getPrimaryFieldsError =
          action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default webformSlice.reducer;
export const {
  reset,
  resetUpdateWebformSuccess,
  resetCustomWebformFieldSuccess,
  resetCreateWebformSuccess,
  resetBulkLeadCreationSuccess,
  resetDeleteWebformResponseSuccess,
  resetSingleFormData,
  resetBulkLeadDeleteSuccess,
  resetDeleteWebformSuccess,
  resetAllWebforms,
} = webformSlice.actions;
