import { axios_institute_private } from "../../../../../api/setup.intersepter";
import {
  BillingAutoDebitDTO,
  CreateBillingAddressDTO,
  CreateBillingDTO,
  UpdateBillingAdminsDTO,
  UpdateBranchDTO,
  UpdateSubscriptionStatusDTO,
  UpdateUserDTO,
} from "./types";

export const GetBillingByInstituteId = (data: any) => {
  return axios_institute_private({
    method: "GET",
    // url: `/team/byInstitute/${id}?status=ALL`,
    url: `institutionBilling?instituteId=${data.id}&status=${data.status}`,
  });
};

export const GetSubscriptionByInstituteId = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `institutionSubscription?instituteId=${id}`,
  });
};

export const CreateBilling = (body: CreateBillingDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/institutionBillingPayment?actionType=payNow`,
    // url: `/institutionBillingPayment`,
    data: body,
  });
};

export const CheckPromocodeByName = (name: string) => {
  return axios_institute_private({
    method: "GET",
    url: `promocode?promocodeName=${name}`,
  });
};

export const UpdateSubscriptionStatus = (body: UpdateSubscriptionStatusDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/institutionSubscription/updateStatus`,
    data: body,
  });
};

export const GetBillingAdmins = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `team/billingAdmin?instituteId=${id}`,
  });
};

export const RemoveBillingAdmin = (body: any) => {
  return axios_institute_private({
    method: "PUT",
    url: `/team/billingAdmin?billingAdminId=${body?.id}`,
    data: body,
  });
};

export const UpdateBillingAdmins = (body: UpdateBillingAdminsDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/team/billingAdmin`,
    data: body,
  });
};

export const GetBillingAddress = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `institutionBillingAddress?instituteId=${id}`,
  });
};

export const UpdateBillingAddress = (body: CreateBillingAddressDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/institutionBillingAddress`,
    data: body,
  });
};

export const RemoveUserOrUserLicense = (body: UpdateUserDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/institutionBilling/removeBenefitLicense`,
    data: body,
  });
};

export const RemoveBranchOrBranchLicense = (body: UpdateBranchDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/institutionBilling/removeBenefitLicense`,
    data: body,
  });
};

export const GetPaymentHistory = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `institutionBillingPayment?instituteId=${id}`,
  });
};

export const GetPaymentHistoryByPagination = (
  id: string,
  lastEvaluatedKey: string,
  limit: number,
  lastEvaluatedSortKey: string
) => {
  let url = `/institutionBillingPayment?instituteId=${id}&limit=${limit}`;

  if (lastEvaluatedKey) {
    url += `&lastEvaluatedKey=${lastEvaluatedKey}`;
  }

  if (lastEvaluatedSortKey) {
    url += `&lastEvaluatedSortKey=${lastEvaluatedSortKey}`;
  }

  return axios_institute_private({
    method: "GET",
    url: url,
  });
};

export const GetPaymentInvoice = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `institutionBillingPayment?paymentId=${id}&invoiceType=download`,
  });
};

export const BillingAutoDebit = (
  body: BillingAutoDebitDTO,
  actionType: string
) => {
  return axios_institute_private({
    method: "POST",
    url: `/autoDebit?actionType=${actionType}`,
    data: body,
  });
};
