import {
  createAsyncThunk,
  AnyAction,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";

import { notificationInitialStateDTO } from "./types";
import {
  GetNotificationsByPagination,
  GetNotificationsByPaginationPopover,
  MarkAllAsRead,
  MarkNotificationAsRead,
} from "./Notification.request";

export const initialState: notificationInitialStateDTO = {
  success: false,
  error: false,
  loading: false,
  notificationsPopover: null,
  notifications: null,
  markAsReadLoading: false,
  markAsReadSuccess: false,
  markAsReadError: false,
  markAllAsReadLoading: false,
  markAllAsReadSuccess: false,
  markAllAsReadError: false,
};

export const getNotificationsByPagination = createAsyncThunk(
  "user/getNotificationsByPagination",
  async (
    data: {
      id: string;
      lastEvaluatedKey: string;
      limit: number;
      lastEvaluatedSortKey: string;
    },
    thunkAPI: any
  ) => {
    const { id, lastEvaluatedKey, limit, lastEvaluatedSortKey } = data;

    return GetNotificationsByPagination(
      id,
      lastEvaluatedKey,
      limit,
      lastEvaluatedSortKey
    )
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getNotificationsByPaginationPopover = createAsyncThunk(
  "user/getNotificationsByPaginationPopover",
  async (
    data: {
      id: string;
      lastEvaluatedKey: string;
      limit: number;
      lastEvaluatedSortKey: string;
    },
    thunkAPI: any
  ) => {
    const { id, lastEvaluatedKey, limit, lastEvaluatedSortKey } = data;

    return GetNotificationsByPaginationPopover(
      id,
      lastEvaluatedKey,
      limit,
      lastEvaluatedSortKey
    )
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const markAsRead = createAsyncThunk(
  "user/markAsRead",
  async (
    data: {
      id: string;
    },
    thunkAPI: any
  ) => {
    const { id } = data;

    return MarkNotificationAsRead(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const markAllAsRead = createAsyncThunk(
  "user/markAllAsRead",
  async (
    data: {
      id: string;
    },
    thunkAPI: any
  ) => {
    const { id } = data;

    return MarkAllAsRead(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);
export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getNotificationsByPagination.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getNotificationsByPagination.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.notifications = action?.payload;
      }
    );
    builder.addCase(
      getNotificationsByPagination.rejected,
      (state, action: AnyAction) => {
        state.loading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getNotificationsByPaginationPopover.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getNotificationsByPaginationPopover.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.notificationsPopover = action?.payload;
      }
    );
    builder.addCase(
      getNotificationsByPaginationPopover.rejected,
      (state, action: AnyAction) => {
        state.loading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(markAsRead.pending, (state) => {
      state.markAsReadLoading = true;
      state.markAsReadSuccess = false;
      state.markAsReadError = false;
    });
    builder.addCase(
      markAsRead.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.markAsReadLoading = false;
        state.markAsReadSuccess = true;
        state.markAsReadError = false;
      }
    );
    builder.addCase(markAsRead.rejected, (state, action: AnyAction) => {
      state.markAsReadLoading = false;
      state.markAsReadSuccess = false;
      state.markAsReadError = action?.error?.message || "Something went wrong";
    });
    builder.addCase(markAllAsRead.pending, (state) => {
      state.markAllAsReadLoading = true;
      state.markAllAsReadSuccess = false;
      state.markAllAsReadError = false;
    });
    builder.addCase(
      markAllAsRead.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.markAllAsReadLoading = false;
        state.markAllAsReadSuccess = true;
        state.markAllAsReadError = false;
      }
    );
    builder.addCase(markAllAsRead.rejected, (state, action: AnyAction) => {
      state.markAllAsReadLoading = false;
      state.markAllAsReadSuccess = false;
      state.markAllAsReadError =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default notificationSlice.reducer;
export const { reset } = notificationSlice.actions;
