import {
  axios_institute_private,
  axios_signup,
} from "../../../../../api/setup.intersepter";
import { WhatsappConfigDTO } from "./types";

export const GetWaConfig = () => {
  return axios_institute_private({
    method: "GET",
    url: `/wa/config`,
  });
};

export const ConfigWhatsapp = (body: WhatsappConfigDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/institute/wa`,
    data: body,
  });
};

export const GetWhatsappConfigInstitute = (data: any) => {
  return axios_institute_private({
    method: "GET",
    url: `/institute/wa?instituteId=${data.id}&status=${data.status}`,
  });
};

export const DeleteWhatsapp = (body: WhatsappConfigDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/institute/wa`,
    data: body,
  });
};