import { AnyAction, configureStore } from "@reduxjs/toolkit";
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "./reducers";
import { createWebSocketMiddleware } from "./webSocketMiddleware";
// import { socket } from "../api/socket";

export const store = configureStore({
  reducer: rootReducer,
  // middleware: [thunk, createWebSocketMiddleware(socket), logger],
  middleware: [thunk, logger],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type TypedDispatch = ThunkDispatch<RootState, unknown, AnyAction>;
export type TypedThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
