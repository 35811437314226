import { AnyAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetAttendanceCalendarMarkers,
  GetAttendanceData,
  editStudentAttendance,
  getAttendanceStudentList,
  saveStudentAttendance,
} from "./attendance.request";
import { AttendanceInitialStateDTO } from "./types";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: AttendanceInitialStateDTO = {
  error: false,
  loading: false,
  success: false,
  attendanceListData: null,
  attendanceMarkerError: false,
  attendanceMarkerLoading: false,
  attendanceMarkerSuccess: false,
  attendanceMarkerData: null,
  attendanceStudentListError: false,
  attendanceStudentListLoading: false,
  attendanceStudentListSuccess: false,
  attendanceStudentListData: null,
  attendanceStudentSaveError: false,
  attendanceStudentSaveLoading: false,
  attendanceStudentSaveSuccess: false,
  attendanceStudentEditError: false,
  attendanceStudentEditLoading: false,
  attendanceStudentEditSuccess: false,
};

export const attendanceList = createAsyncThunk(
  "app/attendance",
  (data: any) => {
    const { userId, timestamp, isSuperAdmin, isAdmin } = data;
    return GetAttendanceData(userId, timestamp, isSuperAdmin, isAdmin)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch((error: any) => {
        throw error.response.data;
      });
  }
);

export const attendanceMarkers = createAsyncThunk(
  "app/attendanceMarkers",
  (data: any) => {
    const { userId, startDate, endDate, isSuperAdmin, isAdmin } = data;
    return GetAttendanceCalendarMarkers(
      userId,
      startDate,
      endDate,
      isSuperAdmin,
      isAdmin
    )
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch((error: any) => {
        throw error.response.data;
      });
  }
);

export const attendanceStudentList = createAsyncThunk(
  "app/attendanceStudentList",
  (data: any) => {
    const { batchId, attendanceDate, batchStartTime, batchEndTime } = data;
    return getAttendanceStudentList(
      batchId,
      attendanceDate,
      batchStartTime,
      batchEndTime
    )
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch((error: any) => {
        throw error.response.data;
      });
  }
);

export const attendanceSave = createAsyncThunk(
  "app/attendanceSave",
  (body: any, { dispatch }) => {
    return saveStudentAttendance(body)
      .then((response: any) => {
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch((error: any) => {
        dispatch(
          setToastNotification({
            message: error?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw error.response.data;
      });
  }
);

export const attendanceEdit = createAsyncThunk(
  "app/attendanceEdit",
  (body: any, { dispatch }) => {
    return editStudentAttendance(body)
      .then((response: any) => {
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch((error: any) => {
        dispatch(
          setToastNotification({
            message: error?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw error.response.data;
      });
  }
);

export const attendanceSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {
    reset: () => initialState,
    resetAddUpdateAttendanceSuccess: (state) => {
      state.attendanceStudentEditSuccess = false;
      state.attendanceStudentSaveSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(attendanceList.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    });
    builder.addCase(attendanceList.fulfilled, (state, action: any) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.attendanceListData = action.payload;
    });
    builder.addCase(attendanceList.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(attendanceMarkers.pending, (state) => {
      state.attendanceMarkerLoading = true;
      state.attendanceMarkerError = false;
      state.attendanceMarkerSuccess = false;
    });
    builder.addCase(attendanceMarkers.fulfilled, (state, action: any) => {
      state.attendanceMarkerLoading = false;
      state.attendanceMarkerError = false;
      state.attendanceMarkerSuccess = true;
      state.attendanceMarkerData = action.payload;
    });
    builder.addCase(attendanceMarkers.rejected, (state, action: AnyAction) => {
      state.attendanceMarkerLoading = false;
      state.attendanceMarkerSuccess = false;
      state.attendanceMarkerError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(attendanceStudentList.pending, (state) => {
      state.attendanceStudentListLoading = true;
      state.attendanceStudentListError = false;
      state.attendanceStudentListSuccess = false;
    });
    builder.addCase(attendanceStudentList.fulfilled, (state, action: any) => {
      state.attendanceStudentListLoading = false;
      state.attendanceStudentListError = false;
      state.attendanceStudentListSuccess = true;
      state.attendanceStudentListData = action.payload;
    });
    builder.addCase(
      attendanceStudentList.rejected,
      (state, action: AnyAction) => {
        state.attendanceStudentListLoading = false;
        state.attendanceStudentListSuccess = false;
        state.attendanceStudentListError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(attendanceSave.pending, (state) => {
      state.attendanceStudentSaveLoading = true;
      state.attendanceStudentSaveError = false;
      state.attendanceStudentSaveSuccess = false;
    });
    builder.addCase(attendanceSave.fulfilled, (state, action: any) => {
      state.attendanceStudentSaveLoading = false;
      state.attendanceStudentSaveError = false;
      state.attendanceStudentSaveSuccess = true;
    });
    builder.addCase(attendanceSave.rejected, (state, action: AnyAction) => {
      state.attendanceStudentSaveLoading = false;
      state.attendanceStudentSaveSuccess = false;
      state.attendanceStudentSaveError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(attendanceEdit.pending, (state) => {
      state.attendanceStudentEditLoading = true;
      state.attendanceStudentEditError = false;
      state.attendanceStudentEditSuccess = false;
    });
    builder.addCase(attendanceEdit.fulfilled, (state, action: any) => {
      state.attendanceStudentEditLoading = false;
      state.attendanceStudentEditError = false;
      state.attendanceStudentEditSuccess = true;
    });
    builder.addCase(attendanceEdit.rejected, (state, action: AnyAction) => {
      state.attendanceStudentEditLoading = false;
      state.attendanceStudentEditSuccess = false;
      state.attendanceStudentEditError =
        action?.error?.message || "Something went wrong";
    });
  },
});
export default attendanceSlice.reducer;
export const { reset, resetAddUpdateAttendanceSuccess } =
  attendanceSlice.actions;
