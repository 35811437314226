export enum ActionTypes {
  RESET_STATE = "RESET_STATE",
}
export const resetState = () => ({
  type: ActionTypes.RESET_STATE,
});

interface SendMessageAction {
  type: "WEBSOCKET/SEND_MESSAGE";
  event: string;
  payload: any; // Define a more specific type based on your payload structure
}

export const sendMessage = (message: string): SendMessageAction => ({
  type: "WEBSOCKET/SEND_MESSAGE",
  event: "your_event_name", // Replace with your actual event name
  payload: message,
});
