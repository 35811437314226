import { axios_institute_private } from "../../../../../api/setup.intersepter";
import { UserDetailsData } from "./type";

export const SaveUserDetails = (body: UserDetailsData) => {
  return axios_institute_private({
    method: "PUT",
    url: `/team/updateProfilebyId`,
    data: body,
  });
};

export const GetUserById = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/team?teamMemberId=${id}`,
  });
};
