export default function CompareTncPrivacyPolicy(userDetails: any) {
  if (
    userDetails?.tnc?.TnC === userDetails?.institute[0]?.tnc?.version &&
    userDetails?.tnc?.Privacy_Policy ===
      userDetails?.institute[0]?.privacyPolicyTnC?.version
  ) {
    return { tnc: true, privacy: true };
  } else if (
    userDetails?.tnc?.TnC === userDetails?.institute[0]?.tnc?.version &&
    userDetails?.tnc?.Privacy_Policy !==
      userDetails?.institute[0]?.privacyPolicyTnC?.version
  ) {
    return { tnc: true, privacy: false };
  } else if (
    userDetails?.tnc?.TnC !== userDetails?.institute[0]?.tnc?.version &&
    userDetails?.tnc?.Privacy_Policy ===
      userDetails?.institute[0]?.privacyPolicyTnC?.version
  ) {
    return { tnc: false, privacy: true };
  } else {
    return { tnc: false, privacy: false };
  }
}
