import { axios_institute_private } from "../../../../api/setup.intersepter";
import {
  CreateCustomRoleDTO,
  CreateStudentIdDTO,
  NotificationSettingsDTO,
  TaxInvoiceSettingsDTO,
} from "./types";

export const GetNotificationSettings = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `institute/notificationSettings?instituteId=${id}`,
  });
};

export const UpdateNotificationSettings = (body: NotificationSettingsDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/institute/notificationSettings`,
    data: body,
  });
};

export const GetStudentInvoiceSettings = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `institute/studentTaxInvoice?instituteId=${id}`,
  });
};

export const CreateStudentInvoiceSettings = (body: TaxInvoiceSettingsDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/institute/studentTaxInvoice`,
    data: body,
  });
};

export const UpdateStudentInvoiceSettings = (body: TaxInvoiceSettingsDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/institute/studentTaxInvoice`,
    data: body,
  });
};

export const CreateCustomRole = (body: CreateCustomRoleDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/role`,
    data: body,
  });
};

export const UpdateCustomRole = (body: CreateCustomRoleDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/role`,
    data: body,
  });
};

export const GetRolesByInstitute = (id: string) => {
  return axios_institute_private({
    method: "GET",
    // url: `/role?instituteId=ALL`,
    url: `/role?instituteId=${id}&includeSystemRoles=true&includeRoleUser=true`,
  });
};

export const GetRoleDomainStructure = () => {
  return axios_institute_private({
    method: "GET",
    url: `/roleDomain?instituteId=ALL&status=ACTIVE`,
  });
};

export const GetRoleById = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `role?roleId=${id}`,
  });
};

export const DeleteRole = (body: any) => {
  return axios_institute_private({
    method: "DELETE",
    url: `/role`,
    data: body,
  });
};

export const CreateStudentId = (body: CreateStudentIdDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/studentId`,
    data: body,
  });
};

export const UpdateStudentId = (body: CreateStudentIdDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/studentId`,
    data: body,
  });
};

export const GetStudentIdSettings = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `studentId?instituteId=${id}`,
  });
};
