import { createAsyncThunk, AnyAction, createSlice } from "@reduxjs/toolkit";
import { EmailConfigData, ImapInitialConfigDTO } from "./types";
import { SaveImapEmailConfigDetails } from "./inbound.request";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: ImapInitialConfigDTO = {
  imapConfigSuccess: false,
  imapConfigError: false,
  imapConfigLoading: false,
};

export const saveImapEmailConfigDetails = createAsyncThunk(
  "user/saveImapConfigDetails",
  async (data: EmailConfigData, thunkAPI) => {
    return SaveImapEmailConfigDetails(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const inboundImapSlice = createSlice({
  name: "imap",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(saveImapEmailConfigDetails.pending, (state) => {
      state.imapConfigLoading = true;
      state.imapConfigSuccess = false;
      state.imapConfigError = false;
    });
    builder.addCase(
      saveImapEmailConfigDetails.fulfilled,
      (state, action: AnyAction) => {
        state.imapConfigLoading = false;
        state.imapConfigSuccess = action?.payload?.message || "Email synced";
        state.imapConfigError = false;
      }
    );
    builder.addCase(
      saveImapEmailConfigDetails.rejected,
      (state, action: AnyAction) => {
        state.imapConfigLoading = false;
        state.imapConfigSuccess = false;
        state.imapConfigError =
          action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default inboundImapSlice.reducer;
export const { reset } = inboundImapSlice.actions;
