import { createAsyncThunk, AnyAction, createSlice } from "@reduxjs/toolkit";
import { SavingImportDTO, SavingImportedData } from "./types";
import { SaveImportedFile } from "./inbound.request";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: SavingImportDTO = {
  saveImportedDataSuccess: false,
  saveImportedDataLoading: false,
  saveImportedDataError: false,
};

export const saveImportedFile = createAsyncThunk(
  "user/saveImportedFile",
  async (data: SavingImportedData, { dispatch }) => {
    return SaveImportedFile(data)
      .then((response: any) => {
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const importSlice = createSlice({
  name: "import",
  initialState,
  reducers: {
    reset: () => initialState,
    resetSaveImportedDataSuccess: (state) => {
      state.saveImportedDataSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveImportedFile.pending, (state) => {
      state.saveImportedDataLoading = true;
      state.saveImportedDataSuccess = false;
      state.saveImportedDataError = false;
    });
    builder.addCase(saveImportedFile.fulfilled, (state, action: AnyAction) => {
      state.saveImportedDataLoading = false;
      state.saveImportedDataSuccess = true;
      state.saveImportedDataError = false;
    });
    builder.addCase(saveImportedFile.rejected, (state, action: AnyAction) => {
      state.saveImportedDataLoading = false;
      state.saveImportedDataSuccess = false;
      state.saveImportedDataError =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default importSlice.reducer;
export const { reset, resetSaveImportedDataSuccess } = importSlice.actions;
