import { axios_institute_private } from "../../../../api/setup.intersepter";

export const GetAttendanceData = (
  userId: string,
  timestamp: number,
  isSuperAdmin: boolean,
  isAdmin: boolean
) => {
  if (isSuperAdmin) {
    return axios_institute_private({
      method: "GET",
      url: `/studentAttendance?instituteId=${userId}&timestamp=${timestamp}
`,
    });
  } else if (isAdmin) {
    return axios_institute_private({
      method: "GET",
      url: `/studentAttendance?branchId=${userId}&timestamp=${timestamp}
`,
    });
  } else {
    return axios_institute_private({
      method: "GET",
      url: `/studentAttendance?userId=${userId}&timestamp=${timestamp}
`,
    });
  }
};

export const GetAttendanceCalendarMarkers = (
  userId: string,
  startDate: number,
  endDate: number,
  isSuperAdmin: boolean,
  isAdmin: boolean
) => {
  if (isSuperAdmin) {
    return axios_institute_private({
      method: "GET",
      url: `/studentAttendance?instituteId=${userId}&startDate=${startDate}&endDate=${endDate}
`,
    });
  } else if (isAdmin) {
    return axios_institute_private({
      method: "GET",
      url: `/studentAttendance?branchId=${userId}&startDate=${startDate}&endDate=${endDate}
`,
    });
  } else {
    return axios_institute_private({
      method: "GET",
      url: `/studentAttendance?userId=${userId}&startDate=${startDate}&endDate=${endDate}
`,
    });
  }
};

export const getAttendanceStudentList = (
  batchId: string,
  attendanceDate: any,
  batchStartTime: any,
  batchEndTime: any
) => {
  return axios_institute_private({
    method: "GET",
    url: `/studentAttendance?batchId=${batchId}&attendanceDate=${attendanceDate}&batchStartTime=${batchStartTime}&batchEndTime=${batchEndTime}`,
  });
};

export const saveStudentAttendance = (body: any) => {
  return axios_institute_private({
    method: "POST",
    url: `/studentAttendance`,
    data: body,
  });
};

export const editStudentAttendance = (body: any) => {
  return axios_institute_private({
    method: "PUT",
    url: `/studentAttendance`,
    data: body,
  });
};
