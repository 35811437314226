import { axios_institute_private } from "../../../../../api/setup.intersepter";
import { WebformCustomfieldDTO, WebformDTO } from "./types";

export const GetAllWebforms = (body: any) => {
  let url = `/institute/webform?instituteID=${body.instituteId}&limit=${body.limit}&status=ALL`;
  if (body.branchId) {
    url += `&branchId=${body.branchId}`;
  }

  if (body.lastEvaluatedKey) {
    url += `&lastEvaluatedKey=${body.lastEvaluatedKey}`;
  }

  if (body.lastEvaluatedSortKey) {
    url += `&lastEvaluatedSortKey=${body.lastEvaluatedSortKey}`;
  }
  return axios_institute_private({
    method: "GET",
    url: url,
  });
};

export const CreateWebform = (body: WebformDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/institute/webform`,
    data: body,
  });
};

export const GetWebformByInstitute = (data: any) => {
  return axios_institute_private({
    method: "GET",
    url: `/institute/webform?instituteID=${data?.instituteId}&webFormID=${data?.webformId}`,
  });
};

export const GetPrimaryFieldsByInstitute = (
  id: string,
  lastEvaluatedKey: string,
  limit: number,
  lastEvaluatedSortKey: string
) => {
  if (lastEvaluatedKey && lastEvaluatedSortKey) {
    return axios_institute_private({
      method: "GET",
      url: `/institute/webformCustomField?instituteID=${id}&limit=${limit}&status=ACTIVE&lastEvaluatedKey=${lastEvaluatedKey}&lastEvaluatedSortKey=${lastEvaluatedSortKey}`,
    });
  } else {
    return axios_institute_private({
      method: "GET",
      url: `/institute/webformCustomField?instituteID=${id}&limit=${limit}&status=ACTIVE`,
    });
  }
};

export const GetPrimaryFieldsByInstituteForBranch = (
  id: string,
  lastEvaluatedKey: string,
  limit: number,
  lastEvaluatedSortKey: string,
  branchId: string
) => {
  if (lastEvaluatedKey && lastEvaluatedSortKey) {
    return axios_institute_private({
      method: "GET",
      url: `/institute/webformCustomField?instituteID=${id}&limit=${limit}&status=ACTIVE&branchId=${branchId}&lastEvaluatedKey=${lastEvaluatedKey}&lastEvaluatedSortKey=${lastEvaluatedSortKey}`,
    });
  } else {
    return axios_institute_private({
      method: "GET",
      url: `/institute/webformCustomField?instituteID=${id}&limit=${limit}&status=ACTIVE&branchId=${branchId}`,
    });
  }
};

export const UpdateWebform = (body: any) => {
  return axios_institute_private({
    method: "PUT",
    url: `/institute/webform`,
    data: body,
  });
};

export const GetWebformResponse = (body: any) => {
  let url = `/institute/webform/getWebformSubmission?instituteId=${body.instituteId}&webformId=${body.formId}&limit=${body.limit}&status=ALL`;
  if (body.branchId) {
    url += `&branchId=${body.branchId}`;
  }

  if (body.lastEvaluatedKey) {
    url += `&lastEvaluatedKey=${body.lastEvaluatedKey}`;
  }

  if (body.lastEvaluatedSortKey) {
    url += `&lastEvaluatedSortKey=${body.lastEvaluatedSortKey}`;
  }
  return axios_institute_private({
    method: "GET",
    url: url,
  });
};

export const CreateCustomFieldWebform = (body: WebformCustomfieldDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/institute/webformCustomField`,
    data: body,
  });
};

export const GetStudentContactById = (id: any) => {
  return axios_institute_private({
    method: "GET",
    url: `/studentContact?studentContactId=${id}`,
  });
};

export const BulkLeadCreation = (body: any) => {
  return axios_institute_private({
    method: "POST",
    url: `/nurture/lead/studentContactToEnquiryFromWebform`,
    data: body,
  });
};

export const DeleteWebformResponse = (body: any) => {
  return axios_institute_private({
    method: "DELETE",
    url: `/studentContact`,
    data: body,
  });
};

export const DeleteWebform = (id: string) => {
  return axios_institute_private({
    method: "DELETE",
    url: `/institute/webform?webformId=${id}`,
  });
};
