import { axios_institute_private } from "../../../../api/setup.intersepter";
import { PrivacyPolicyData, TermsAndConditionData } from "./types";

export const getDashboardData = (body: any) => {
  return axios_institute_private({
    method: "GET",
    url: `/institutions/dashboard/${body.instituteId}?userId=${body.userId}&month=${body.month}&year=${body.year}`,
  });
};

export const getDashboardDatas = (body: any) => {
  return axios_institute_private({
    method: "GET",
    url: `/institutions/newdashboard/${body.instituteId}?userId=${body.userId}&branchId=${body.branchId}`,
  });
};

export const GetRevenueAnalyticsGraphData = (body: any) => {
  return axios_institute_private({
    method: "GET",
    url: `/institutions/revenueAnalyticGraph?instituteId=${body.instituteId}&dateFilterType=${body.dateFilterType}&dateFilterValue=${body.dateFilterValue}&branchId=${body.branchId}`,
  });
};

export const GetLeadSourceGraphData = (body: any) => {
  return axios_institute_private({
    method: "GET",
    url: `/institutions/leadSourceGraph?instituteId=${body.instituteId}&dateFilterType=${body.dateFilterType}&dateFilterValue=${body.dateFilterValue}&branchId=${body.branchId}`,
  });
};

export const GetOverduePayments = (body: any) => {
  let url = `/institutions/getOverduePayments?instituteId=${body.instituteId}&branchId=${body.branchId}&limit=${body.limit}`;
  if (body.lastEvaluatedKey) {
    url += `&lastEvaluatedKey=${body.lastEvaluatedKey}`;
  }

  if (body.lastEvaluatedSortKey) {
    url += `&lastEvaluatedSortKey=${body.lastEvaluatedSortKey}`;
  }
  return axios_institute_private({
    method: "GET",
    url: url,
  });
};

export const CaptureTNC = (body: TermsAndConditionData) => {
  return axios_institute_private({
    method: "PUT",
    url: `/tnc`,
    data: body,
  });
};

export const CapturePrivacyPolicy = (body: PrivacyPolicyData) => {
  return axios_institute_private({
    method: "PUT",
    url: `/tnc`,
    data: body,
  });
};
