import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import {
  GetInstituteByIdRequest,
  InstitutionsRequest,
} from "./institute.request";
import {
  InstitutionsDTO,
  InstitutionsDetailsDTO,
  InstitutionsInitialStateDTO,
} from "./types";
import { SetActiveInstitute } from "../../../../../service/activeInstituteSlice";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: InstitutionsInitialStateDTO = {
  error: false,
  success: false,
  loading: false,
  instituteDetails: null,
  fileUploadSuccess: false,
  instituteDetailsLoading: false,
  instituteUpdateloading: false,
  instituteUpdateSuccess: false,
  instituteByIdLoading: false,
};

export const institute = createAsyncThunk(
  "/institutes",
  (data: InstitutionsDTO, { dispatch }) => {
    return InstitutionsRequest(data)
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(SetActiveInstitute(data?.id));
        }
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);
export const getInstituteById = createAsyncThunk(
  "/institute/id",
  (data: string, { dispatch }) => {
    return GetInstituteByIdRequest(data)
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(SetActiveInstitute(response?.data?.data?.id));
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const instituteSlice = createSlice({
  name: "institute",
  initialState,
  reducers: {
    reset: () => initialState,
    clearSuccess: (state) => {
      state.success = false;
    },
    resetInstituteProfileUpdateSuccess: (state) => {
      state.success = false;
    },
    clearUpdateSuccess: (state) => {
      state.instituteUpdateSuccess = false;
    },
    updateInstituteDetails: (
      state,
      action: PayloadAction<InstitutionsDetailsDTO>
    ) => {
      state.instituteDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(institute.pending, (state) => {
      state.instituteUpdateloading = true;
      state.error = false;
      state.instituteUpdateSuccess = false;
    });
    builder.addCase(institute.fulfilled, (state, action: AnyAction) => {
      state.instituteUpdateloading = false;
      state.instituteUpdateSuccess =
        action?.payload?.message || "Institute Profile Updated Successfully";
      state.error = false;
    });
    builder.addCase(institute.rejected, (state, action: AnyAction) => {
      state.instituteUpdateloading = false;
      state.error = action?.payload?.message || "Something went wrong";
      state.instituteUpdateSuccess = false;
    });
    builder.addCase(getInstituteById.pending, (state) => {
      state.instituteByIdLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getInstituteById.fulfilled,
      (state, action: PayloadAction<InstitutionsDetailsDTO>) => {
        state.instituteByIdLoading = false;
        state.success = true;
        state.error = false;
        state.instituteDetails = action?.payload;
      }
    );
    builder.addCase(getInstituteById.rejected, (state, action: AnyAction) => {
      state.instituteByIdLoading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
  },
});

export default instituteSlice.reducer;
export const { reset, clearUpdateSuccess, updateInstituteDetails } =
  instituteSlice.actions;
