import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { CreateContact, UpdateContact } from "./campaign.request";
import { CampaignStateDTO } from "./types";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: CampaignStateDTO = {
  success: false,
  error: false,
  loading: false,
  contactUpdateSuccess: false,
  contactUpdateError: false,
  contactUpdateLoading: false,
  contactDetails: {},
};

export const createContact = createAsyncThunk(
  "campaign/createcontact",
  async (data: any, thunkAPI: any) => {
    return CreateContact(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);
export const updateContact = createAsyncThunk(
  "campaign/updatecontact",
  async (data: any, thunkAPI: any) => {
    return UpdateContact(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(createContact.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      createContact.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.contactDetails = action.payload;
      }
    );
    builder.addCase(createContact.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(updateContact.pending, (state) => {
      state.contactUpdateLoading = true;
      state.contactUpdateSuccess = false;
      state.contactUpdateError = false;
    });
    builder.addCase(
      updateContact.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.contactUpdateLoading = false;
        state.contactUpdateSuccess = true;
        state.contactUpdateError = false;
        state.contactDetails = action.payload;
      }
    );
    builder.addCase(updateContact.rejected, (state, action: AnyAction) => {
      state.contactUpdateLoading = false;
      state.contactUpdateSuccess = false;
      state.contactUpdateError =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default campaignSlice.reducer;
export const { reset } = campaignSlice.actions;
