import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { CreateTagRequest } from "./tag.request";
import { CreateTagDTO, createTagIntialStateDTO } from "./types";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: createTagIntialStateDTO = {
  success_createTag: false,
  error_createTag: false,
  loading_createTag: false,
  newAddedSelectedTag: [],
};

export const createTag = createAsyncThunk(
  "user/tag",
  (data: CreateTagDTO, thunkAPI) => {
    return CreateTagRequest(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {
    reset: () => initialState,
    setUserDetails: (state, action: PayloadAction<CreateTagDTO>) => {
      return { ...state, userDetails: action.payload };
    },
    resetNewAddedSelectedTag: (state) => {
      return { ...state, newAddedSelectedTag: [] };
    },
    removeNewTag: (state, action: PayloadAction) => {
      return { ...state, newAddedSelectedTag: action.payload };
    },
    resetSuccessCreateTag: (state) => {
      state.success_createTag = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createTag.pending, (state) => {
      state.loading_createTag = true;
      state.success_createTag = false;
      state.error_createTag = false;
    });
    builder.addCase(createTag.fulfilled, (state, action: AnyAction) => {
      const obj = {
        id: action.payload.id,
        tagname: action.meta.arg.tagName,
        status: "UNDER_REVIEW",
      };
      state.loading_createTag = false;
      state.success_createTag = true;
      state.error_createTag = false;
      state.newAddedSelectedTag = [...state.newAddedSelectedTag, obj];
    });
    builder.addCase(createTag.rejected, (state, action: AnyAction) => {
      state.loading_createTag = false;
      state.success_createTag = false;
      state.error_createTag = action?.error?.message || "Something went wrong";
    });
  },
});

export default tagSlice.reducer;
export const {
  reset,
  setUserDetails,
  resetNewAddedSelectedTag,
  removeNewTag,
  resetSuccessCreateTag,
} = tagSlice.actions;
