import { axios_institute_public } from "../../../../api/setup.intersepter";

import { CampaignStateDTO } from "./types";

export const CreateContact = (data: any) => {
  return axios_institute_public({
    method: "POST",
    url: `/studentContact`,
    data: data,
  });
};
export const UpdateContact = (data: any) => {
  return axios_institute_public({
    method: "PUT",
    url: `/studentContact?studentContactId=${data.id}&demoTiming=${data.demoTiming}`,
  });
};
