import {
  axios_institute_private,
  axios_openSearch,
} from "../../../../../api/setup.intersepter";
import { OpenSearchStudentsDTO } from "./types";

export const GetStudentByInstituteIdRequest = (
  id: string,
  lastEvaluatedKey: string,
  limit: number,
  lastEvaluatedSortKey: string,
  branchId: string,
  assigneeId: string
) => {
  let url = `/student/mappedStudentByInstitute/${id}?paymentStatus=All&courseId=All&batchId=All&branchId=${branchId}&batchStatus=All&limit=${limit}`;

  if (assigneeId) {
    url += `&assigneeId=${assigneeId}`;
  }
  if (lastEvaluatedKey) {
    url += `&lastEvaluatedKey=${lastEvaluatedKey}`;
  }

  if (lastEvaluatedSortKey) {
    url += `&lastEvaluatedSortKey=${lastEvaluatedSortKey}`;
  }

  return axios_institute_private({
    method: "GET",
    url: url,
  });
};

export const OpenSearchStudentListing = (body: OpenSearchStudentsDTO) => {
  return axios_openSearch({
    method: "POST",
    url: `/elastic_search/nurtureSearch`,
    data: body,
  });
};
