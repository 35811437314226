import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { GetUserById, SaveUserDetails } from "./userdetails.request";
import { UserDetailsDTO, UserDetailsData } from "./type";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";
import { GetRoleById } from "../../../Settings/service/settings.request";

export const initialState: UserDetailsDTO = {
  userById: null,
  userRoleById: null,
  error: false,
  success: false,
  loading: false,
  updateUserDetailserror: false,
  updateUserDetailsSuccess: false,
  updateUserDetailsLoading: false,
  userByIdLoading: false,
  userRoleByIdLoading: false,
};

export const updateUserDetails = createAsyncThunk(
  "/userDetails",
  async (data: UserDetailsData, thunkAPI) => {
    return SaveUserDetails(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getUserById = createAsyncThunk(
  "/userById",
  async (id: string, thunkAPI: any) => {
    return GetUserById(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getUserRoleById = createAsyncThunk(
  "user/getUserRoleById",
  async (id: string) => {
    return GetRoleById(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(updateUserDetails.pending, (state) => {
      state.updateUserDetailsLoading = true;
      state.updateUserDetailsSuccess = false;
      state.updateUserDetailserror = false;
    });
    builder.addCase(updateUserDetails.fulfilled, (state, action: AnyAction) => {
      state.updateUserDetailsLoading = false;
      state.updateUserDetailsSuccess =
        action?.payload?.message || "User details updated";
      state.updateUserDetailserror = false;
    });
    builder.addCase(updateUserDetails.rejected, (state, action: AnyAction) => {
      state.updateUserDetailsLoading = false;
      state.updateUserDetailsSuccess = false;
      state.updateUserDetailserror =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getUserById.pending, (state) => {
      state.userByIdLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getUserById.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.userByIdLoading = false;
        state.success = true;
        state.error = false;
        state.userById = action?.payload;
      }
    );
    builder.addCase(getUserById.rejected, (state, action: AnyAction) => {
      state.userByIdLoading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getUserRoleById.pending, (state) => {
      state.userRoleByIdLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getUserRoleById.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.userRoleByIdLoading = false;
        state.success = true;
        state.error = false;
        state.userRoleById = action?.payload;
      }
    );
    builder.addCase(getUserRoleById.rejected, (state, action: AnyAction) => {
      state.userRoleByIdLoading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
  },
});

export default userDetailsSlice.reducer;
export const { reset } = userDetailsSlice.actions;
