import { AnyAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ForgetPasswordOTPRequest,
  ForgetPasswordResetPasswordRequest,
  ForgetPasswordVerifyOTPRequest,
} from "./forgetPassword.request";
import {
  forgetPasswordInitialStateDTO,
  requsestOTPDTO,
  resetPasswordDTO,
  verifyOTPDTO,
} from "./types";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";
export const initialState: forgetPasswordInitialStateDTO = {
  loading_requestOTP: false,
  success_requestOTP: false,
  error_requestOTP: false,
  loading_verifyOTP: false,
  success_verifyOTP: false,
  error_verifyOTP: false,
  loading_resetPassword: false,
  success_resetPassword: false,
  error_resetPassword: false,
};
export const otpRequest = createAsyncThunk(
  "user/forgetPassword/otpRequest",
  async (data: requsestOTPDTO, thunkAPI: any) => {
    return ForgetPasswordOTPRequest(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }

        const resp = data.resend
          ? { ...response?.data, resend: data.resend }
          : response?.data;
        return resp;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);
export const verifyOTP = createAsyncThunk(
  "user/forgetPassword/verifyOTP",
  async (data: verifyOTPDTO, thunkAPI: any) => {
    return ForgetPasswordVerifyOTPRequest(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);
export const resetPassword = createAsyncThunk(
  "user/forgetPassword/resetPassword",
  async (data: resetPasswordDTO, thunkAPI: any) => {
    return ForgetPasswordResetPasswordRequest(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);
export const forgetPasswordSlice = createSlice({
  name: "forgetPassword",
  initialState,
  reducers: {
    reset: () => initialState,
    resetForgotPassword: (state) => {
      state.success_requestOTP = false;
      state.success_verifyOTP = false;
      state.success_resetPassword = false;
    },
    resetForgotPasswordOtpSuccess: (state) => {
      state.success_verifyOTP = false;
    },
    resetForgotPasswordsuccess_requestOTP: (state) => {
      state.success_requestOTP = false;
    },
    resetForgotPasswordsuccess_resetPassword: (state) => {
      state.success_resetPassword = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(otpRequest.pending, (state, action: any) => {
      if (action?.meta?.arg?.resend) {
        state.loading_requestOTP = false;
        state.success_requestOTP = true;
        state.error_requestOTP = false;
        state.loading_verifyOTP = false;
        state.success_verifyOTP = false;
        state.error_verifyOTP = false;
        state.loading_resetPassword = false;
        state.success_resetPassword = false;
        state.error_resetPassword = false;
      } else {
        state.loading_requestOTP = true;
        state.success_requestOTP = false;
        state.error_requestOTP = false;
        state.loading_verifyOTP = false;
        state.success_verifyOTP = false;
        state.error_verifyOTP = false;
        state.loading_resetPassword = false;
        state.success_resetPassword = false;
        state.error_resetPassword = false;
      }
    });
    builder.addCase(otpRequest.fulfilled, (state) => {
      state.loading_requestOTP = false;
      state.success_requestOTP = true;
      state.error_requestOTP = false;
      state.loading_verifyOTP = false;
      state.success_verifyOTP = false;
      state.error_verifyOTP = false;
      state.loading_resetPassword = false;
      state.success_resetPassword = false;
      state.error_resetPassword = false;
    });
    builder.addCase(otpRequest.rejected, (state, action: AnyAction) => {
      state.loading_requestOTP = false;
      state.success_requestOTP = false;
      state.error_requestOTP = action?.error?.message || "Something went wrong";
      state.loading_verifyOTP = false;
      state.success_verifyOTP = false;
      state.error_verifyOTP = false;
      state.loading_resetPassword = false;
      state.success_resetPassword = false;
      state.error_resetPassword = false;
    });
    builder.addCase(verifyOTP.pending, (state) => {
      state.loading_verifyOTP = true;
      state.success_verifyOTP = false;
      state.error_verifyOTP = false;
      state.loading_resetPassword = false;
      state.success_resetPassword = false;
      state.error_resetPassword = false;
    });
    builder.addCase(verifyOTP.fulfilled, (state) => {
      state.loading_verifyOTP = false;
      state.success_verifyOTP = true;
      state.error_verifyOTP = false;
      state.loading_resetPassword = false;
      state.success_resetPassword = false;
      state.error_resetPassword = false;
    });
    builder.addCase(verifyOTP.rejected, (state, action: AnyAction) => {
      state.loading_verifyOTP = false;
      state.success_verifyOTP = false;
      state.error_verifyOTP = action?.error?.message || "Something went wrong";
      state.loading_resetPassword = false;
      state.success_resetPassword = false;
      state.error_resetPassword = false;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.loading_resetPassword = true;
      state.success_resetPassword = false;
      state.error_resetPassword = false;
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.loading_resetPassword = false;
      state.success_resetPassword = true;
      state.error_resetPassword = false;
    });
    builder.addCase(resetPassword.rejected, (state, action: AnyAction) => {
      state.loading_resetPassword = false;
      state.success_resetPassword = false;
      state.error_resetPassword =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default forgetPasswordSlice.reducer;
export const {
  reset,
  resetForgotPassword,
  resetForgotPasswordOtpSuccess,
  resetForgotPasswordsuccess_requestOTP,
  resetForgotPasswordsuccess_resetPassword,
} = forgetPasswordSlice.actions;
