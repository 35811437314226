import { axios_institute_private } from "../../../../../../api/setup.intersepter";
import { FileUploadDTO } from "./types";

export const FileUploadRequest = (body: FileUploadDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/fileUpload`,
    data: body,
  });
};
