import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  CapturePrivacyPolicy,
  CaptureTNC,
  getDashboardData,
  getDashboardDatas,
  GetLeadSourceGraphData,
  GetOverduePayments,
  GetRevenueAnalyticsGraphData,
} from "./dashboard.request";
import {
  DashboardInitialStateDTO,
  DashboardEntryPayloadDTO,
  TermsAndConditionData,
  PrivacyPolicyData,
} from "./types";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: DashboardInitialStateDTO = {
  error: false,
  loading: false,
  success: false,
  dashboardData: null,
  revenuGraphData: null,
  leadSourceGraphData: null,
  overduePaymentsData: null,
  overduePaymentsloading: false,
  revenuGraphloading: false,
  leadSourceGraphloading: false,
  termsAndConditionsLoading: false,
  termsAndConditionsSuccess: false,
  termsAndConditionsError: false,
  privacyPolicyLoading: false,
  privacyPolicySuccess: false,
  privacyPolicyError: false,
};

export const dashboard = createAsyncThunk("app/dashboard", (data: string) => {
  return getDashboardData(data)
    .then((response: any) => {
      return response?.data?.data;
    })
    .catch((error: any) => {
      throw error.response.data;
    });
});

export const dashboardDatas = createAsyncThunk(
  "app/dashboardData",
  (data: string) => {
    return getDashboardDatas(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch((error: any) => {
        throw error.response.data;
      });
  }
);

export const getRevenueAnalyticsGraphData = createAsyncThunk(
  "app/revenueGraphData",
  (data: string) => {
    return GetRevenueAnalyticsGraphData(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch((error: any) => {
        throw error.response.data;
      });
  }
);

export const getLeadSourceGraphData = createAsyncThunk(
  "app/leadSourceGraphData",
  (data: string) => {
    return GetLeadSourceGraphData(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch((error: any) => {
        throw error.response.data;
      });
  }
);

export const getOverduePayments = createAsyncThunk(
  "app/getOverduePayments",
  (data: string) => {
    return GetOverduePayments(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch((error: any) => {
        throw error.response.data;
      });
  }
);

export const termsAndConditions = createAsyncThunk(
  "app/tnc",
  (data: TermsAndConditionData, { dispatch }) => {
    return CaptureTNC(data)
      .then((response: any) => {
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch((error: any) => {
        dispatch(
          setToastNotification({
            message: error?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw error.response.data;
      });
  }
);
export const privacyPolicy = createAsyncThunk(
  "app/privacy",
  (data: PrivacyPolicyData, { dispatch }) => {
    return CapturePrivacyPolicy(data)
      .then((response: any) => {
        if (response) {
          dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch((error: any) => {
        dispatch(
          setToastNotification({
            message: error?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw error.response.data;
      });
  }
);
export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(dashboardDatas.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    });
    builder.addCase(
      dashboardDatas.fulfilled,
      (state, action: PayloadAction<DashboardEntryPayloadDTO>) => {
        state.loading = false;
        state.error = false;
        state.success = true;
        state.dashboardData = action.payload;
      }
    );
    builder.addCase(dashboardDatas.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getRevenueAnalyticsGraphData.pending, (state) => {
      state.revenuGraphloading = true;
      state.error = false;
      state.success = false;
    });
    builder.addCase(
      getRevenueAnalyticsGraphData.fulfilled,
      (state, action: PayloadAction<DashboardEntryPayloadDTO>) => {
        state.revenuGraphloading = false;
        state.error = false;
        state.success = true;
        state.revenuGraphData = action.payload;
      }
    );
    builder.addCase(
      getRevenueAnalyticsGraphData.rejected,
      (state, action: AnyAction) => {
        state.revenuGraphloading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getLeadSourceGraphData.pending, (state) => {
      state.leadSourceGraphloading = true;
      state.error = false;
      state.success = false;
    });
    builder.addCase(
      getLeadSourceGraphData.fulfilled,
      (state, action: PayloadAction<DashboardEntryPayloadDTO>) => {
        state.leadSourceGraphloading = false;
        state.error = false;
        state.success = true;
        state.leadSourceGraphData = action.payload;
      }
    );
    builder.addCase(
      getLeadSourceGraphData.rejected,
      (state, action: AnyAction) => {
        state.leadSourceGraphloading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getOverduePayments.pending, (state) => {
      state.overduePaymentsloading = true;
      state.error = false;
      state.success = false;
    });
    builder.addCase(
      getOverduePayments.fulfilled,
      (state, action: PayloadAction<DashboardEntryPayloadDTO>) => {
        state.overduePaymentsloading = false;
        state.error = false;
        state.success = true;
        state.overduePaymentsData = action.payload;
      }
    );
    builder.addCase(getOverduePayments.rejected, (state, action: AnyAction) => {
      state.overduePaymentsloading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(termsAndConditions.pending, (state) => {
      state.termsAndConditionsLoading = true;
      state.termsAndConditionsSuccess = false;
      state.termsAndConditionsError = false;
    });
    builder.addCase(termsAndConditions.fulfilled, (state, action: any) => {
      state.termsAndConditionsLoading = false;
      state.termsAndConditionsSuccess = true;
      state.termsAndConditionsError = false;
    });
    builder.addCase(termsAndConditions.rejected, (state, action: AnyAction) => {
      state.termsAndConditionsLoading = false;
      state.termsAndConditionsSuccess = false;
      state.termsAndConditionsError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(privacyPolicy.pending, (state) => {
      state.privacyPolicyLoading = true;
      state.privacyPolicySuccess = false;
      state.privacyPolicyError = false;
    });
    builder.addCase(privacyPolicy.fulfilled, (state, action: any) => {
      state.privacyPolicyLoading = false;
      state.privacyPolicySuccess = true;
      state.privacyPolicyError = false;
    });
    builder.addCase(privacyPolicy.rejected, (state, action: AnyAction) => {
      state.privacyPolicyLoading = false;
      state.privacyPolicySuccess = false;
      state.privacyPolicyError =
        action?.error?.message || "Something went wrong";
    });
  },
});
export default dashboardSlice.reducer;
export const { reset } = dashboardSlice.actions;
