import { axios_institute_private } from "../../../../../api/setup.intersepter";
import { InstitutionsDTO } from "./types";

export const InstitutionsRequest = (body: InstitutionsDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/institutions`,
    data: body,
  });
};
export const GetInstituteByIdRequest = (Id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/institutions?instituteId=${Id}`,
  });
};
