export const updateInstituteProfile = (data: {}) => {
  return {
    type: "INSTITUTE_PROFILE_UPDATE",
    payload: data,
  };
};

// profileReducer.ts
interface ProfileState {
  instituteDetails: {};
}

const initialState: ProfileState = {
  instituteDetails: {
    instituteProfileImage: "",
    instituteName: "",
  },
};

const instituteProfileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "INSTITUTE_PROFILE_UPDATE":
      return {
        ...state,
        instituteDetails: action.payload,
      };
    default:
      return state;
  }
};

export default instituteProfileReducer;
